import * as React from 'react';
import { orangeButtonColor } from '../../../constants';
import { ButtonBase, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import CheckIcon from '@mui/icons-material/Check';

const StyledButtonBase = styled(ButtonBase, {
  shouldForwardProp: (prop) => prop !== 'focusVisible',
})(({ theme, focusVisible }) => ({
  position: 'relative',
  height: 207,
  width: 207,
  ...(focusVisible && {
    zIndex: 1,
    '& .imageBackdrop': {
      opacity: 0.1,
    },
    '& .imageTitle': {
      border: '1px solid currentColor',
    },
  }),
  '&:hover': {
    zIndex: 1,
    '& .imageBackdrop': {
      opacity: 0.1,
    },
    '& .imageTitle': {
      border: '1px solid currentColor',
    },
  },
  '&.Mui-disabled': {
    '& .disabledOverlay': {
      opacity: 0.7,
    },
  },
}));

const ImageSrc = styled('span')({
  position: 'absolute',
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  backgroundSize: 'cover',
  backgroundPosition: 'center 40%',
});

const ImageBackdrop = styled('span')(({ theme }) => ({
  position: 'absolute',
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  backgroundColor: theme.palette.common.black,
  opacity: 0.3,
  transition: theme.transitions.create('opacity'),
}));

const DisabledOverlay = styled('span')(({ theme }) => ({
  position: 'absolute',
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  backgroundColor: 'lightgray',
  opacity: 0,
  transition: theme.transitions.create('opacity'),
}));

const ImageButtonWrapper = styled('span')(({ theme }) => ({
  position: 'absolute',
  left: 0,
  right: 0,
  top: 0,
  bottom: 0,
  display: 'flex',
  alignItems: 'flex-end',
  justifyContent: 'center',
  color: theme.palette.common.white,
}));

const ImageTitle = styled(Typography)(({ theme }) => ({
  width: '100%',
  position: 'relative',
  padding: `calc(${theme.spacing(2)} ${theme.spacing(4)} ${theme.spacing(1)} + 6px)`,
  background: orangeButtonColor,
}));

export const ImageButton = (props) => {
  const {
    title,
    checked,
    disabled,
    onClick,
    image,
    children,
    ...rest
  } = props;

  const childrenWithProps = React.Children.map(children, (child) => {
    if (React.isValidElement(child)) {
      return React.cloneElement(child, { ...rest });
    }
    return child;
  });

  return (
    <StyledButtonBase
      onClick={onClick}
      disabled={disabled}
      focusRipple
      key={title}
      disableRipple={disabled}
    >
      {childrenWithProps}
      <ImageSrc
        style={{
          backgroundImage: `url(${image})`,
        }}
      />
      <ImageBackdrop className="imageBackdrop" />
      <ImageButtonWrapper className="imageButton">
        {checked && (
          <CheckIcon
            color="primary"
            sx={{
              fontSize: 45,
              bottom: '50%',
              position: 'absolute',
            }}
          />
        )}
      </ImageButtonWrapper>
      <ImageButtonWrapper className="imageButton">
        <ImageTitle
          component="span"
          variant="subtitle1"
          color="inherit"
          padding={2}
          className="imageTitle"
        >
          {title}
        </ImageTitle>
      </ImageButtonWrapper>
      <DisabledOverlay className="disabledOverlay" />
    </StyledButtonBase>
  );
};