import React from "react"

import {Rect, Text} from "react-konva"

import {FONT_SIZE} from "../../../constants"

const positionTextSpacing = 2
const materialLabelSpacing = 13

const renderMeasurements = (screenLine, screenWidth, cRail) => {
  return (
    <React.Fragment>
      {
        cRail !== undefined && cRail.x !== undefined && cRail.y !== undefined &&
        <Text
          name="position"
          x={-75 - positionTextSpacing - (cRail.orientation === 'vertical' ? screenWidth / 2 : 0)}
          y={positionTextSpacing + (cRail.orientation === 'horizontal' ? screenWidth / 2 : 0)}
          width={75}
          height={FONT_SIZE}
          fontSize={FONT_SIZE}
          align="right"
          verticalAlign="top"
          text={cRail.x + "/" + cRail.y}
          fill="black"
        />
      }
      {
        cRail !== undefined && cRail.length !== undefined &&
        <Text
          name="length"
          x={cRail.orientation === 'horizontal' ? screenLine.length / 2 - 50 : -FONT_SIZE / 2 + 1}
          y={cRail.orientation === 'horizontal' ? -FONT_SIZE / 2 + 1 : -screenLine.length / 2 + 50}
          width={100}
          height={FONT_SIZE}
          rotation={cRail.orientation === 'horizontal' ? 0 : -90}
          fontSize={FONT_SIZE}
          align="center"
          verticalAlign="bottom"
          text={cRail.length}
          fill="black"
        />
      }
    </React.Fragment>
  )
}

const renderMaterialLabel = (cRail) => {
  if (!cRail) {
    return
  }
  const text =
    cRail.material === 'stainless' ? 'V4A'
      : cRail.material === 'galvanized' ? 'FZ'
      : ''
  const x =
    cRail.orientation === 'horizontal' ? materialLabelSpacing
      : cRail.orientation === 'vertical' ? -FONT_SIZE / 2 + 1
      : 0
  const y =
    cRail.orientation === 'horizontal' ? -FONT_SIZE / 2 + 1
      : cRail.orientation === 'vertical' ? -materialLabelSpacing
      : 0
  const rotation = cRail.orientation === 'vertical' ? -90 : 0
  const opacity = cRail.length >= 20 ? 1 : 0

  return (
    <React.Fragment>
      {
        cRail.material !== undefined &&
        <Text
          opacity={opacity}
          name="material"
          x={x}
          y={y}
          width={70}
          height={FONT_SIZE}
          rotation={rotation}
          fontSize={FONT_SIZE}
          verticalAlign="bottom"
          text={text}
          fill="black"
        />
      }
    </React.Fragment>
  )
}

export const renderGroupElements = (
  screenLine,
  cRail,
  dashed = true,
  showMeasurements = true,
  color = 'blue',
  fill = 'white'
) => {
  const cRailWidth = 3.8
  const screenWidth = screenLine.length * cRailWidth / cRail.length
  switch (cRail.orientation) {
    case 'horizontal':
      return (
        <React.Fragment>
          <Rect
            y={-screenWidth / 2}
            width={screenLine.length}
            height={screenWidth}
            stroke={color}
            strokeWidth={1}
            dash={dashed ? [10, 5] : []}
            fill={fill}
          />
          {showMeasurements && renderMeasurements(screenLine, screenWidth, cRail)}
          {showMeasurements && renderMaterialLabel(cRail)}
        </React.Fragment>
      )

    case 'vertical':
      return (
        <React.Fragment>
          <Rect
            x={-screenWidth / 2}
            y={-screenLine.length}
            width={screenWidth}
            height={screenLine.length}
            stroke={color}
            strokeWidth={1}
            dash={dashed ? [10, 5] : []}
            fill={fill}
          />
          {showMeasurements && renderMeasurements(screenLine, screenWidth, cRail)}
          {showMeasurements && renderMaterialLabel(cRail)}
        </React.Fragment>
      )

    default:
      return []
  }
}

