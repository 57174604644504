import {createSlice} from "@reduxjs/toolkit";
import billingReducer from "./billingSlice";
import deliveryReducer from "./deliverySlice";
import {planActionTypes} from "../constants";
import {planApiActionTypes} from "../constants";

const initialState = {
  vaultQuantity: 1,
  lidQuantity: 0,
  intermediateCeilingQuantity: 0,
  floorQuantity: 0
}

export const projectRootSlice = createSlice({
  name: "billing",
  initialState,
  reducers: {
    reset: () => initialState,
    setVaultQuantity: (state, action) => Number.isInteger(action.payload)
      ? ({...state, vaultQuantity: action.payload})
      : state,
    setLidQuantity: (state, action) => Number.isInteger(action.payload)
      ? ({...state, lidQuantity: action.payload})
      : state,
    setIntermediateCeilingQuantity: (state, action) => Number.isInteger(action.payload)
      ? ({...state, intermediateCeilingQuantity: action.payload})
      : state,
    setFloorQuantity: (state, action) => Number.isInteger(action.payload)
      ? ({...state, floorQuantity: action.payload})
      : state
  },
  extraReducers: (builder) => {
    builder.addCase(planActionTypes.setVault, (state) => {
      return state.vaultQuantity > 0 ? state : ({...state, vaultQuantity: 1})
    })
    builder.addCase(planActionTypes.unsetVault, (state) => {
      return state.vaultQuantity === 0 ? state : ({...state, vaultQuantity: 0})
    })
    builder.addCase(planActionTypes.setFloor, (state) => {
      return state.floorQuantity > 0 ? state : ({...state, floorQuantity: 1})
    })
    builder.addCase(planActionTypes.unsetFloor, (state) => {
      return state.floorQuantity === 0 ? state : ({...state, floorQuantity: 0})
    })
    builder.addCase(planActionTypes.setLid, (state) => {
      return state.lidQuantity > 0 ? state : ({...state, lidQuantity: 1})
    })
    builder.addCase(planActionTypes.unsetLid, (state) => {
      return state.lidQuantity === 0 ? state : ({...state, lidQuantity: 0})
    })
    builder.addCase(planActionTypes.setIntermediateCeiling, (state) => {
      return state.intermediateCeilingQuantity > 0 ? state : ({...state, intermediateCeilingQuantity: 1})
    })
    builder.addCase(planActionTypes.unsetIntermediateCeiling, (state) => {
      return state.intermediateCeilingQuantity === 0 ? state : ({...state, intermediateCeilingQuantity: 0})
    })
    builder.addCase(planApiActionTypes.readPlanSuccess, (state, action) => {
      return {
        ...state,
        vaultQuantity: !!action.data.vault ? 1 : 0,
        floorQuantity: !!action.data.floor ? 1 : 0,
        lidQuantity: !!action.data.lid ? 1 : 0,
        intermediateCeilingQuantity: !!action.data.intermediateCeiling ? 1 : 0
      }
    })
  }
});

export const {
  reset,
  setVaultQuantity,
  setLidQuantity,
  setIntermediateCeilingQuantity,
  setFloorQuantity,
} = projectRootSlice.actions;

export const projectReducer = (state = initialState, action) => {
  const rootState = projectRootSlice.reducer(state, action)
  const deliveryState = deliveryReducer(rootState.delivery, action)
  const billingState = billingReducer(rootState.billing, action)

  if (rootState.delivery === deliveryState && rootState.billing === billingState)
    return rootState;
  const {delivery, billing, ...others} = rootState;

  return {
    ...others,
    delivery: deliveryState,
    ...(billingState && {billing: billingState})
  };
}
