export const frontendUrl = "https://app.mannhart.swiss"

export const apiUrls = {
  authenticationService: "https://auth.mannhart.swiss",
  userEndpoint: "https://auth.mannhart.swiss/user",
  releaseNotesEndpoint: "https://auth.mannhart.swiss/releaseNote.php",
  plansEndpoint: "https://project.mannhart.swiss/plans",
  planOwnerEndpoint: "https://project.mannhart.swiss/changeowner",
  projectsEndpoint: "https://project.mannhart.swiss/projects",
  deliveryAddressEndpoint: "https://project.mannhart.swiss/deliveryaddress.php",
  billingAddressEndpoint: "https://project.mannhart.swiss/billingaddress.php",
  projectStatusEndpoint: "https://project.mannhart.swiss/changestatus",
  planPdfDownload: "https://project.mannhart.swiss/pdfs.php",
  detailsEndpoint: "https://project.mannhart.swiss/detail.php",
  armouringDataEndpoint: "https://project.mannhart.swiss/armouring.php",
  armouringDocumentsEndpoint: "https://mannhart-prod-armouring-factory.hsig.app"
}

export const mannhartWebsite = "https://mannhart.swiss"
export const termsAndConditions = "https://mannhart.swiss"
export const privacyPolicy = "https://mannhart.swiss"
export const releaseNotes = "https://mannhart.swiss/kabelschacht-app-versionen"
