import React from "react";
import {Grid, Paper, Typography} from "@mui/material";
import {useRouteMatch} from "react-router-dom";
import {orangeBackgroundColor} from "../../../constants";
import {VaultPlan} from "../../Plan/Configuration/VaultPlan/VaultPlan";
import {useForwardPlanStepKeyboardShortcuts} from "../forwardPlanStepKeyboardShortcuts";

const TextGridItem = () => {
  return (
    <Grid item xs={12} key={'upper_text'}>
      <Typography component="p" variant="body2" align={'center'} style={{fontSize: 0.92 + 'rem'}}>
        Positionieren Sie die Elemente per Drag & Drop auf der Seitenwand. Die Aussparungen und C-Schienen können in
        die gewünschte Grösse gezogen werden. Mit einem Doppelklick entfernen Sie das Element.
      </Typography>
    </Grid>
  )
}

export const VaultStep = () => {
  const match = useRouteMatch();
  const wallIndex = (m => {
    switch (m) {
      case '/step/2a':
        return 0
      case '/step/2b':
        return 1
      case '/step/2c':
        return 2
      case '/step/2d':
        return 3
      default:
        return 0
    }
  })(match.path);
  const {childRef} = useForwardPlanStepKeyboardShortcuts();

  return (
    <Paper square={true} sx={{
    background: orangeBackgroundColor,
    padding: '1em 2em 2em 2em'
  }} elevation={10}>
      <Grid container spacing={1}>
        <TextGridItem/>
        <Grid item xs={12}>
          <VaultPlan
            childRef={childRef}
            wallIndex={wallIndex}
            isToolbarVisible={true}
          />
        </Grid>
      </Grid>
    </Paper>
  )
};
