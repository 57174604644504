import React, {forwardRef, useCallback, useEffect, useImperativeHandle} from "react"
import {useDispatch, useSelector} from "react-redux"

import {Layer, Rect, Stage, Transformer} from "react-konva"

import {createPart} from "../GroundPlan/part"
import {
  createHorizontalMeasurements,
  createVerticalMeasurements,
} from "../../measurement"
import {
  getPartialXMeasurements,
  getPartialYMeasurements
} from "../../configurationPlanMeasurement"
import {createVerticallyCenteredGrid} from "../grid"
import {AutoResizeDiv} from "../../AutoResizeDiv"
import {renderToolbar} from "./lidPlanToolbar"
import {renderGroupElements as renderManholeGroupElements} from "../../Element/manholeRenderFactory"
import {createRectangularElementCrossHairLayer} from "../../Element/rectangularElementCrossHairs"
import {renderRectangularPlanElement} from "../../Element/rectangularElementFunctions";
import {createLidDomainFunctions} from "../../../domain/lidDomainFunctions";
import {createCircularElementCrossHairLayer} from "../../Element/circularElementCrossHairs";
import {createGroundPlanLayout} from "../planLayout";
import {renderGroupElements as renderBlockOutGroupElements} from "../../Element/blockOutRenderFactory";
import {renderCircularPlanElement} from "../../Element/circularElementFunctions";
import {renderGroupElements as renderCircularBlockOutGroupElements} from "../../Element/circularBlockOutRenderFactory";
import {useCopyPaste} from "../CopyPasteContext";
import uuid from "react-uuid";
import {selectionActions} from "../../../../actions";
import {useConfigurationPlanInteractions} from "../configurationPlanInteractions";

const Plan = forwardRef((props, ref) => {
  const {clientSize} = props
  const dispatch = useDispatch()
  const plan = useSelector(state => state.plan)
  const selectedIds = useSelector(state => state.selection)
  const layout = createGroundPlanLayout(clientSize, plan)
  const domainFunctions = createLidDomainFunctions(dispatch, plan)
  const {copy, paste} = useCopyPaste(domainFunctions)
  const getResizeDirections = manhole =>
    plan.lid.type === 'B125' && plan.lid.collar !== 'without' ? ["right", "top"]
      : manhole.orientation === 'vertical' ? ["right"] : ["top"]

  const clearSelection = useCallback(() => {
    dispatch(selectionActions.clearSelected());
  }, [dispatch]);

  useEffect(() => {
    clearSelection();
  }, [clearSelection]);

  const getHorizontalMeasurementStops = (layout, manholes, blockOuts, circularBlockOuts) => {
    const wallWidth = (layout.part.realWidth - layout.configArea.realWidth) / 2
    const outerStops = [wallWidth, layout.configArea.realWidth, wallWidth]
    const innerStops = getPartialXMeasurements(circularBlockOuts, [], [...manholes, ...blockOuts], layout.configArea.realWidth)
    const innerOffset = wallWidth + (manholes.length === 1 && manholes[0].x < 0 ? manholes[0].x : 0)

    return innerStops.length > 1
      ? {stops: [innerStops, outerStops], offsets: [innerOffset, 0]}
      : {stops: [outerStops], offsets: [0]}
  }

  const getVerticalMeasurementStops = (layout, manholes, blockOuts, circularBlockOuts) => {
    const wallWidth = (layout.part.realHeight - layout.configArea.realHeight) / 2
    const outerStops = [wallWidth, layout.configArea.realHeight, wallWidth]
    const innerStops = getPartialYMeasurements(circularBlockOuts, [], [...manholes, ...blockOuts], layout.configArea.realHeight)
    const getClearMeasureTop = () => layout.configArea.realHeight
    const getManholeTop = () => manholes.length === 1 ? manholes[0].y + manholes[0].height : 0
    const getInnerOffset = () => wallWidth
      - (
        getManholeTop() > getClearMeasureTop()
          ? getManholeTop() - getClearMeasureTop()
          : 0
      )

    return innerStops.length > 1
      ? {stops: [outerStops, innerStops], offsets: [0, getInnerOffset()]}
      : {stops: [outerStops], offsets: [0]}
  }

  const renderMeasurements = (layout, manholes, blockOuts, circularBlockOuts) => {
    const horizontalMeasurementStops = getHorizontalMeasurementStops(layout, manholes, blockOuts, circularBlockOuts)
    const verticalMeasurementStops = getVerticalMeasurementStops(layout, manholes, blockOuts, circularBlockOuts)

    return (
      <React.Fragment>
        {
          createHorizontalMeasurements(
            layout.horizontalMeasurements,
            horizontalMeasurementStops.stops,
            horizontalMeasurementStops.offsets
          )
        }
        {
          createVerticalMeasurements(
            layout.verticalMeasurements,
            verticalMeasurementStops.stops,
            verticalMeasurementStops.offsets
          )
        }
      </React.Fragment>
    )
  }

  const handleCopy = () => {
    const toCopy = {
      context: 'lid_' + plan.lid.type,
      manholes: plan.lid.manholes.filter(manhole => selectedIds.includes(manhole.id)),
      lidBlockOuts: plan.lid.blockOuts.filter(blockOut => selectedIds.includes(blockOut.id)),
      circularLidBlockOuts: plan.lid.circularBlockOuts.filter(blockOut => selectedIds.includes(blockOut.id))
    }
    copy(toCopy);
  };

  const handlePaste = () => {
    const isArrayWithElements = (arr) => Array.isArray(arr) && arr.length > 0;
    const pastedObject = paste();
    if (
      !pastedObject
      || pastedObject.context !== 'lid_' + plan.lid.type
      || (isArrayWithElements(pastedObject.lidBlockOuts) && plan.lid.collar !== 'lifted')
      || (isArrayWithElements(pastedObject.circularLidBlockOuts) && plan.lid.collar !== 'lifted')
    ) {
      return;
    }
    const translateY = (elements, y) =>
      Array.isArray(elements)
        ? elements.map(element => {
          return {...element, y: element.y + y}
        })
        : null
    const translateYBy = -20;
    const translated = ({
      manholes: translateY(pastedObject.manholes, translateYBy),
      lidBlockOuts: translateY(pastedObject.lidBlockOuts, translateYBy),
      circularLidBlockOuts: translateY(pastedObject.circularLidBlockOuts, translateYBy)
    })
    const pasteElements = (elements, addFunc) =>
      isArrayWithElements(elements)
        ? elements.map(element => {
          const id = uuid();

          addFunc({...element, id: id});
          return id;
        })
        : []

    dispatch(selectionActions.setSelected([
      ...pasteElements(translated.manholes, domainFunctions.manholeFunctions.add),
      ...pasteElements(translated.lidBlockOuts, domainFunctions.blockOutFunctions.add),
      ...pasteElements(translated.circularLidBlockOuts, domainFunctions.circularBlockOutFunctions.add)
    ]));
    copy({...pastedObject, ...translated});
  };

  const handleDelete = () => {
    const del = (elements, functions) =>
      elements.filter(el => selectedIds.includes(el.id)).forEach(el => functions.remove(el.id))

    del(plan.lid.manholes, domainFunctions.manholeFunctions);
    del(plan.lid.blockOuts, domainFunctions.blockOutFunctions);
    del(plan.lid.circularBlockOuts, domainFunctions.circularBlockOutFunctions);
    dispatch(selectionActions.clearSelected)
  };

  useImperativeHandle(ref, () => ({
    delete: () => handleDelete(),
    copy: () => handleCopy(),
    paste: () => handlePaste()
  }));

  const {
    transformerRef,
    layerRef,
    selectionRectRef,
    checkDeselect,
    onMouseDown,
    onMouseMove,
    onMouseUp,
    onClickTap,
    onDblClickTap,
    onDragStart,
    handleTransformerDragStart,
    handleTransformerDragMove,
    handleTransformerDragEnd,
  } = useConfigurationPlanInteractions(ref, props, layout, handleDelete);

  return (
    <div>
      {!isNaN(layout.scale) &&
        <Stage
          name={"stage"}
          width={clientSize.width}
          height={layout.overall.screenHeight}
          onMouseDown={onMouseDown}
          onMouseUp={onMouseUp}
          onMouseMove={onMouseMove}
          onTouchStart={checkDeselect}
          onClick={onClickTap}
          onTap={onClickTap}
          onDblClick={onDblClickTap}
          onDblTap={onDblClickTap}
          onDragStart={onDragStart}
        >
          <Layer ref={layerRef}>
            {createPart(layout.part, layout.configArea)}
            {createVerticallyCenteredGrid(layout.configArea, layout.configAreaRealWorldTransform)}
            {renderMeasurements(layout, plan.lid.manholes, plan.lid.blockOuts, plan.lid.circularBlockOuts)}
            {
              plan.lid.manholes.map(manhole => renderRectangularPlanElement(
                domainFunctions.manholeFunctions,
                layout.configArea,
                manhole,
                (screenRect, manhole) => renderManholeGroupElements(
                  screenRect,
                  manhole,
                  plan.lid,
                  layout.configArea
                ),
                getResizeDirections(manhole),
                false
              ))
            }
            {
              plan.lid.blockOuts.map(blockOut => renderRectangularPlanElement(
                domainFunctions.blockOutFunctions,
                layout.configArea,
                blockOut,
                (screenRect, blockOut) => renderBlockOutGroupElements(
                  screenRect,
                  blockOut
                )
              ))
            }
            {
              plan.lid.circularBlockOuts.map(blockOut => renderCircularPlanElement(
                domainFunctions.circularBlockOutFunctions,
                layout.configArea,
                blockOut,
                (screenCircle, blockOut) => renderCircularBlockOutGroupElements(
                  screenCircle,
                  blockOut
                )
              ))
            }
            {
              renderToolbar(
                plan,
                domainFunctions,
                layout.toolbar,
                layout.configArea
              )
            }
            <Transformer
              ref={transformerRef}
              resizeEnabled={false}
              rotateEnabled={false}
              draggable={true}
              onDragStart={handleTransformerDragStart}
              onDragMove={handleTransformerDragMove}
              onDragEnd={handleTransformerDragEnd}
            />
            <Rect fill="rgba(0,0,255,0.5)" ref={selectionRectRef}/>
          </Layer>
          {createRectangularElementCrossHairLayer()}
          {createCircularElementCrossHairLayer()}
        </Stage>
      }
    </div>
  )
});

export const LidPlan = props => {
  const {childRef} = props

  return (
    <AutoResizeDiv>
      <Plan ref={childRef} {...props}/>
    </AutoResizeDiv>
  )
}
