import React from "react"
import {
  Switch,
  Route,
  useRouteMatch,
  Redirect,
  useHistory,
  useLocation
} from "react-router-dom"
import {Container, Grid, Button, Alert, useTheme} from "@mui/material"
import {useDispatch, useSelector} from "react-redux"

import {Header} from "../Header"
import {Stepper} from "./Stepper"
import {Step1} from "./Step1"
import {OrderStep} from "./OrderStep"
import {BillingStep} from "./BillingStep"
import {VerificationStep} from "./VerificationStep"
import {VaultStep} from "./VaultStep/VaultStep"
import {BottomBar} from "./BottomBar"
import {LidStep} from "./LidStep/LidStep"
import {FloorStep} from "./FloorStep/FloorStep"
import {GuardedRoute} from "../GuardedRoute"
import {isLoginRequired} from "../../helpers/authHelper"
import {authenticationActions, userApiActions} from "../../actions"
import {releaseNotes} from "../../constants"
import {CopyPasteProvider} from "../Plan/Configuration/CopyPasteContext";

const ReleaseNotesInfo = () => {
  const dispatch = useDispatch()
  const history = useHistory()
  const location = useLocation();
  const user = useSelector(state => state.user)
  const authentication = useSelector(state => state.authentication)

  const markReleaseNotesRead = () => {
    if (isLoginRequired(authentication)) {
      history.push("/login?navigateTo=" + location.pathname)
      return
    }
    dispatch(authenticationActions.extendLoginIfRequired())
    dispatch(userApiActions.markReleaseNotesRead())
  }

  return (
    !!user.email &&
    !user.readReleaseNote && (
      <Grid item xs={12}>
        <Alert
          severity={"info"}
          action={
            <Button color="inherit" size="small" onClick={markReleaseNotesRead}>
              Als gelesen markieren
            </Button>
          }
        >
          Wir haben die Kabelschacht App für Sie verbessert! &nbsp;
          <a target="_blank" rel="noreferrer" href={releaseNotes}>
            Was ist neu...
          </a>
        </Alert>
      </Grid>
    )
  )
}

export const Step = () => {
  const theme = useTheme()
  const match = useRouteMatch()
  const authentication = useSelector(state => state.authentication)

  return (
    <CopyPasteProvider>
      <Container
        maxWidth="xl"
        style={{paddingBottom: theme.spacing(4), minWidth: "1000px"}}
      >
        <Grid container spacing={1}>
          <Grid item xs={12}>
            <Route path={`${match.path}/:step`} component={Header}/>
          </Grid>
          <Grid item xs={12}>
            <Route path={`${match.path}/:step`} component={Stepper}/>
          </Grid>
          <ReleaseNotesInfo></ReleaseNotesInfo>
          <Grid item xs={12}>
            <Switch>
              <Route path={`${match.path}/1`} component={Step1}/>
              <Route path={`${match.path}/2a`} component={VaultStep}/>
              <Route path={`${match.path}/2b`} component={VaultStep}/>
              <Route path={`${match.path}/2c`} component={VaultStep}/>
              <Route path={`${match.path}/2d`} component={VaultStep}/>
              <Route path={`${match.path}/3`} component={LidStep}/>
              <Route path={`${match.path}/4`} component={FloorStep}/>
              <GuardedRoute
                path={`${match.path}/5`}
                component={OrderStep}
                auth={() => authentication.loggedIn}
                navigateTo={`${match.path}/5`}
              />
              <GuardedRoute
                path={`${match.path}/6`}
                component={BillingStep}
                auth={() => authentication.loggedIn}
                navigateTo={`${match.path}/6`}
              />
              <GuardedRoute
                path={`${match.path}/7`}
                component={VerificationStep}
                auth={() => authentication.loggedIn}
                navigateTo={`${match.path}/7`}
              />
              <Route component={() => <Redirect to="/step/1"/>}/>
            </Switch>
          </Grid>
          <Grid item xs={12}>
            <Route path={`${match.path}/:step`} component={BottomBar}/>
          </Grid>
        </Grid>
      </Container>
    </CopyPasteProvider>
  )
}
