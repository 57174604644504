import {selectionActionTypes as actionTypes, planActionTypes, planApiActionTypes} from "../constants";

const initialState = [];

export const selectionReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.setSelected:
      return action.data;

    case actionTypes.addSelected:
      return [...state, action.data];

    case actionTypes.removeSelected:
      return state.filter(id => id !== action.data);

    case actionTypes.clearSelected:
    case planActionTypes.reset:
    case planApiActionTypes.readPlanSuccess:
      return initialState;

    case planActionTypes.addSleeve:
      return [action.data.sleeve.id];

    case planActionTypes.addGrounding:
      return [action.data.grounding.id];

    case planActionTypes.addWallDrainage:
      return [action.data.drainage.id];

    case planActionTypes.addBlockOut:
      return [action.data.blockOut.id];

    case planActionTypes.addCRail:
      return [action.data.cRail.id];

    case planActionTypes.addFloorDrainage:
    case planActionTypes.addManhole:
    case planActionTypes.addLidBlockOut:
    case planActionTypes.addCircularLidBlockOut:
    case planActionTypes.addIntermediateCeilingBlockOut:
    case planActionTypes.addCircularIntermediateCeilingBlockOut:
    case planActionTypes.resizeBlockOut:
    case planActionTypes.resizeCRail:
    case planActionTypes.resizeManhole:
    case planActionTypes.resizeLidBlockOut:
    case planActionTypes.resizeCircularLidBlockOut:
    case planActionTypes.resizeIntermediateCeilingBlockOut:
    case planActionTypes.resizeCircularIntermediateCeilingBlockOut:
      return [action.data.id];

    case planActionTypes.removeSleeve:
    case planActionTypes.removeGrounding:
    case planActionTypes.removeWallDrainage:
    case planActionTypes.removeBlockOut:
    case planActionTypes.removeCRail:
    case planActionTypes.removeFloorDrainage:
    case planActionTypes.removeManhole:
    case planActionTypes.removeLidBlockOut:
    case planActionTypes.removeCircularLidBlockOut:
    case planActionTypes.removeIntermediateCeilingBlockOut:
    case planActionTypes.removeCircularIntermediateCeilingBlockOut:
      return state.filter(id => id !== action.data.id);

    default:
      return state;
  }
}

