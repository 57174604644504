import React from "react";
import {Grid, Paper, Typography} from "@mui/material";
import {orangeBackgroundColor} from "../../../constants";
import {FloorPlan} from "../../Plan/Configuration/Floor/FloorPlan";
import {useForwardPlanStepKeyboardShortcuts} from "../forwardPlanStepKeyboardShortcuts";

const TextGridItem = () => {
  return (
    <Grid item xs={12} key={'upper_text'}>
      <Typography component="p" variant="body2" align={'center'} style={{fontSize: 0.92 + 'rem'}}>
        Positionieren Sie die Abflüsse per Drag & Drop auf dem Boden. Mit einem Doppelklick entfernen Sie das Element.
      </Typography>
    </Grid>
  )
}

export const FloorStep = () => {
  const {childRef} = useForwardPlanStepKeyboardShortcuts();

  return (
    <Paper square={true} sx={{
      background: orangeBackgroundColor,
      padding: '1em 2em 2em 2em'
    }} elevation={10}>
      <Grid container spacing={1}>
        <TextGridItem/>
        <Grid item xs={12}>
          <FloorPlan
            childRef={childRef}
            isToolbarVisible={true}
          />
        </Grid>
      </Grid>
    </Paper>
  )
};
