import React, {useEffect, useState} from 'react';
import queryString from "query-string";

import {Typography, Grid, Button, TextField, ButtonGroup, CircularProgress} from '@mui/material';
import { useTheme } from "@mui/material/styles";
import {useDispatch, useSelector} from "react-redux";
import {useHistory} from 'react-router-dom';

import {orangeButtonStyle, dialogStyles} from "../../../constants";
import {authenticationActions} from "../../../actions";
import {isValidEmail} from "../../../validation/emailValidation";

export const Login = props => {
  const navigateToQueryString = queryString.parse(props.location.search).navigateTo;
  const navigateTo = navigateToQueryString === undefined ? '/step/1' : navigateToQueryString;
  const theme = useTheme();
  const dispatch = useDispatch();
  const history = useHistory();
  const [submitted, setSubmitted] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');

  useEffect(() => {dispatch(authenticationActions.logout())}, [dispatch])
  const loggingIn = useSelector(state => state.authentication.loggingIn);
  const error = useSelector(state => state.authentication.error);

  return (
    <React.Fragment>
      <Typography component="h1" variant="h4">
        Willkommen zurück!
      </Typography>
      <Typography sx={dialogStyles.paragraphStyle} component="p" variant="body2">
        Melden Sie sich mit Ihrer Email Adresse an!
      </Typography>
      <form
        style={{marginTop: theme.spacing(6)}}
        name="form"
        noValidate
        onSubmit={e => {
          e.preventDefault();
          setSubmitted(true);
          dispatch(authenticationActions.logout());
          if (isValidEmail(email) && password) {
            dispatch(authenticationActions.login(email, password, navigateTo, history));
          }
        }}
      >
        <TextField
          variant="outlined"
          margin="normal"
          fullWidth
          id="email"
          label="E-Mail"
          name="email"
          autoComplete="email"
          autoFocus
          value={email}
          error={submitted && !isValidEmail(email)}
          helperText={
            !submitted
              ? null
              : !email
              ? "E-Mail ist erforderlich"
              : !isValidEmail(email)
                ? "E-Mail ist ungültig"
                : null
          }
          onChange={e => setEmail(e.target.value)}
        />
        <TextField
          variant="outlined"
          margin="normal"
          fullWidth
          name="password"
          label="Kennwort"
          type="password"
          id="password"
          autoComplete="current-password"
          value={password}
          error={submitted && !password}
          helperText={submitted && !password ? "Kennwort ist erforderlich" : null}
          onChange={e => setPassword(e.target.value)}
        />
        {error &&
        <Typography
          component="p"
          variant="caption"
          color="error"
          style={{
            marginTop: theme.spacing(2)
          }}
        >
          {error.toString()}
        </Typography>
        }
        {loggingIn &&
        <Grid container direction="column" alignItems="center">
          <Grid item>
            <CircularProgress/>
          </Grid>
        </Grid>
        }
        <Button
          type="submit"
          fullWidth
          variant="contained"
          color="primary"
          style={{
            ...orangeButtonStyle,
            margin: theme.spacing(3, 0, 2),
          }}
        >
          Anmelden
        </Button>
      </form>
      <Grid container direction="column" alignItems="center">
        <Grid item>
          <ButtonGroup variant="text" aria-label="full-width contained primary button group">
            <Button onClick={() => {
              if (!navigateTo.includes('step') || (navigateTo.endsWith('/5') || navigateTo.endsWith('/6'))) {
                history.replace("/step/1")
              } else {
                history.replace(navigateTo)
              }
            }}>
              <Typography component="p" color="primary" variant="body2" texttransform="none">
                Zurück zum Konfigurator
              </Typography>
            </Button>
            <Button onClick={() => history.replace("/resetpassword?navigateTo=" + navigateTo)}>
              <Typography component="p" color="primary" variant="body2" texttransform="none">
                Kennwort vergessen?
              </Typography>
            </Button>
            <Button onClick={() => history.replace("/registration?navigateTo=" + navigateTo)}>
              <Typography component="p" color="primary" variant="body2" texttransform="none">
                Registrierung
              </Typography>
            </Button>
          </ButtonGroup>
        </Grid>
      </Grid>
    </React.Fragment>
  );
};

