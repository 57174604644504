import React, {useEffect, useState} from 'react';
import {useDispatch, useSelector} from 'react-redux';
import {authenticationActions} from "../../../actions";
import { CircularProgress, Grid, Typography, useTheme } from "@mui/material";
import {dialogStyles} from "../../../constants";
import queryString from "query-string";

export const EmailVerification = (props) => {
  const theme = useTheme();
  const dispatch = useDispatch();

  // local state
  const [isFirstRender, setIsFirstRender] = useState(true);

  if (isFirstRender) {
    dispatch(authenticationActions.logout());
    setIsFirstRender(false);
  }
  // redux state
  const verified = useSelector(state => state.authentication.verified);
  const verifying = useSelector(state => state.authentication.verifying);
  const error = useSelector(state => state.authentication.error);

  useEffect(() => {
      const code = queryString.parse(props.location.search).code;

      dispatch(authenticationActions.verifyEmail(code));
    },
    [props.location.search, dispatch]);

  return (
    <React.Fragment>
      <Typography component="h1" variant="h4">
        E-Mail Bestätigung
      </Typography>
      {verifying &&
      <React.Fragment>
        <Typography sx={dialogStyles.paragraphStyle} component="p" variant="body2">
          Ihre E-Mail Adresse wird verifiziert, bitte haben sie einen Moment Geduld.
        </Typography>
        <Grid container direction="column" alignItems="center">
          <Grid item>
            <CircularProgress/>
          </Grid>
        </Grid>
      </React.Fragment>
      }
      {error &&
      <Typography
        component="p"
        variant="caption"
        color="error"
        style={{
          marginTop: theme.spacing(2)
        }}
      >
        {error.toString()}
      </Typography>
      }
      {verified &&
      <React.Fragment>
        <Typography sx={dialogStyles.paragraphStyle} component="p" variant="body2">
          Ihre E-Mail Adresse wurde erfolgreich verifiziert und Ihr Konto freigeschalten.
          Sie können sich ab sofort anmelden.
        </Typography>
        <Typography sw={dialogStyles.paragraphStyle} component="p" variant="body2">
          Sie könnenn dieses Browserfenster (Register) jetzt schliessen.
        </Typography>
      </React.Fragment>
      }
    </React.Fragment>
  );
};

