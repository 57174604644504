import {constructionSiteApiActionTypes as actionTypes} from "../constants"

const initialState = []

export const constructionSitesReducer = (state = initialState, action) => {
  switch (action.type) {
    case actionTypes.resetConstructionSites:
      return initialState

    case actionTypes.readConstructionSitesRequest:
      return {fetching: true}

    case actionTypes.readConstructionSitesFailure:
      return {error: action.error}

    case actionTypes.readConstructionSitesSuccess:
      return Array.isArray(action.data) ? [...action.data] : []

    default:
      return state
  }
}
