import React, {useState} from "react"
import {useDispatch, useSelector} from "react-redux"
import {useHistory, useLocation} from "react-router-dom"
import {
  TextField,
  Divider,
  Grid,
  Paper,
  Button,
  Typography,
  useTheme,
  Snackbar, Alert
} from "@mui/material"
import InfoIcon from "@mui/icons-material/Info"
import {isLoginRequired} from "../../helpers/authHelper"
import {authenticationActions, planApiActions} from "../../actions"
import {ActionButtons} from "./ActionButtons"
import {ConfirmDialog} from "../ConfirmDialog"

export const PlanPanel = props => {
  const {plan, project, creator, customer} = props
  const [showOwnerInput, setShowOwnerInput] = useState(false)
  const [owner, setOwner] = useState("")
  const [confirmationDialogOpen, setConfirmationDialogOpen] = useState(false)
  const [snackbar, setSnackbar] = React.useState("");

  const location = useLocation()
  const dispatch = useDispatch()
  const history = useHistory()
  const theme = useTheme()
  const isAdmin = useSelector(state => state.user.isAdmin)

  const authentication = useSelector(state => state.authentication)

  const setOwnerButtonOnClick = () => {
    if (isLoginRequired(authentication)) {
      history.push("/login?navigateTo=" + location.pathname)
      return
    }
    dispatch(authenticationActions.extendLoginIfRequired())
    dispatch(planApiActions.setOwner(plan.id, owner))
  }

  const handleSnackBarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbar("");
  };
  const isDeleteEnabled = !project

  return (
    <Paper square={true} sx={{
      background: "white",
      padding: theme.spacing(4)
    }}>
      <Snackbar
        open={snackbar.length > 0}
        autoHideDuration={3000}
        anchorOrigin={{vertical: 'top', horizontal: 'right'}}
        onClose={handleSnackBarClose}
      >
        <Alert severity="info" sx={{width: '100%'}}>
          {snackbar}
        </Alert>
      </Snackbar>
      <Grid
        container
        direction="row"
        alignItems="center"
        justifyContent="space-between"
      >
        <Grid item>
          <Typography component="h1" variant="h5">
            Plan {plan.id}
          </Typography>
        </Grid>
        {isDeleteEnabled && (
          <Grid item mb={theme.spacing(1)}>
            <Button
              variant="outlined"
              color="primary"
              onClick={() => {
                setConfirmationDialogOpen(true)
              }}
              style={{
                color: "black",
                borderColor: "black"
              }}
            >
              Löschen
            </Button>
            <div>
              <ConfirmDialog
                title="Plan löschen"
                open={confirmationDialogOpen}
                setOpen={setConfirmationDialogOpen}
                onConfirm={() => {
                  dispatch(authenticationActions.extendLoginIfRequired())
                  dispatch(planApiActions.deletePlan(plan.id))
                }}
              >
                <Typography component="p" variant="body2">
                  Sind Sie sicher dass Sie diesen Plan löschen wollen?
                </Typography>
              </ConfirmDialog>
            </div>
          </Grid>

        )}
      </Grid>

      <Divider style={{background: "black"}}/>
      <Grid item mt={theme.spacing(4)}></Grid>
      <Grid container mt={theme.spacing(2)} direction="row">
        <Grid item xs={2}>
          <InfoIcon style={{marginRight: "1em"}}/>
        </Grid>
        <Grid item xs={10}>
          <Typography
            component="p"
            variant="body2"
            style={{whiteSpace: 'pre-line'}}
          >
            Auf Artikelnummer oder Bezeichnung klicken um den Text in die Zwischenablage zu speichern!
          </Typography>
        </Grid>
      </Grid>
      {plan.hasLid && (
        <Grid container mt={theme.spacing(2)} direction="row">
          <Grid item xs={2}>
            <Typography component="p" variant="body2">
              <b>{!!project ? project.lidQuantity : 1} Stk</b>
            </Typography>
          </Grid>
          <Grid item xs={10}>
            <Typography
              component="p"
              variant="body2"
              sx={{ cursor: "pointer" }}
              onClick={_ => {
                navigator.clipboard.writeText(plan.lidPartNumber)
                setSnackbar("Artikelnummer in Zwischenablage kopiert ")
              }}
            >
              <b>{plan.lidPartNumber}</b>
            </Typography>
            <Typography
              component="p"
              variant="body2"
              style={{whiteSpace: 'pre-line'}}
              sx={{ cursor: "pointer" }}
              onClick={_ => {
                navigator.clipboard.writeText(plan.lidDescription)
                setSnackbar("Artikelbeschreibung in Zwischenablage kopiert")
              }}
            >
              {plan.lidDescription}
            </Typography>
          </Grid>
        </Grid>
      )}
      {plan.hasIntermediateCeiling && (
        <Grid container mt={theme.spacing(1)} direction="row">
          <Grid item xs={2}>
            <Typography component="p" variant="body2">
              <b>{!!project ? project.intermediateCeilingQuantity : 1} Stk</b>
            </Typography>
          </Grid>
          <Grid item xs={10}>
            <Typography
              component="p"
              variant="body2"
              sx={{ cursor: "pointer" }}
              onClick={_ => {
                navigator.clipboard.writeText(plan.intermediateCeilingPartNumber)
                setSnackbar("Artikelnummer in Zwischenablage kopiert ")
              }}
            >
              <b>{plan.intermediateCeilingPartNumber}</b>
            </Typography>
            <Typography
              component="p"
              variant="body2"
              sx={{ cursor: "pointer" }}
              style={{whiteSpace: 'pre-line'}}
              onClick={_ => {
                navigator.clipboard.writeText(plan.intermediateCeilingDescription)
                setSnackbar("Artikelbeschreibung in Zwischenablage kopiert")
              }}
            >
              {plan.intermediateCeilingDescription}
            </Typography>
          </Grid>
        </Grid>
      )}
      {plan.hasVault && (
        <Grid container mt={theme.spacing(1)} direction="row">
          <Grid item xs={2}>
            <Typography component="p" variant="body2">
              <b>{!!project ? project.vaultQuantity : 1} Stk</b>
            </Typography>
          </Grid>
          <Grid item xs={10}>
            <Typography
              component="p"
              variant="body2"
              sx={{ cursor: "pointer" }}
              onClick={_ => {
                navigator.clipboard.writeText(plan.vaultPartNumber)
                setSnackbar("Artikelnummer in Zwischenablage kopiert ")
              }}
            >
              <b>{plan.vaultPartNumber}</b>
            </Typography>
            <Typography
              component="p"
              variant="body2" style={{whiteSpace: 'pre-line'}}
              sx={{ cursor: "pointer" }}
              onClick={_ => {
                navigator.clipboard.writeText(plan.vaultDescription)
                setSnackbar("Artikelbeschreibung in Zwischenablage kopiert")
              }}
            >
              {plan.vaultDescription}
            </Typography>
          </Grid>
        </Grid>
      )}
      {plan.hasFloor && (
        <Grid container mt={theme.spacing(1)} direction="row">
          <Grid item xs={2}>
            <Typography component="p" variant="body2">
              <b>{!!project ? project.floorQuantity : 1} Stk</b>
            </Typography>
          </Grid>
          <Grid item xs={10}>
            <Typography
              component="p"
              variant="body2"
              sx={{ cursor: "pointer" }}
              onClick={_ => {
                navigator.clipboard.writeText(plan.floorPartNumber)
                setSnackbar("Artikelnummer in Zwischenablage kopiert ")
              }}
            >
              <b>{plan.floorPartNumber}</b>
            </Typography>
            <Typography
              component="p"
              variant="body2"
              style={{whiteSpace: 'pre-line'}}
              sx={{ cursor: "pointer" }}
              onClick={_ => {
                navigator.clipboard.writeText(plan.floorDescription)
                setSnackbar("Artikelbeschreibung in Zwischenablage kopiert")
              }}
            >
              {plan.floorDescription}
            </Typography>
          </Grid>
        </Grid>
      )}
      <Grid container mt={theme.spacing(3)} direction="row">
        <Grid item xs={5}>
          <Typography component="p" variant="body2">
            Plan erstellt
          </Typography>
        </Grid>
        <Grid item xs={7}>
          <Typography component="p" variant="body2">
            <b>
              {creator.firstname} {creator.lastname}
            </b>
          </Typography>
          <Typography component="p" variant="body2">
            {creator.email}
          </Typography>
          <Typography component="p" variant="body2">
            {new Date(plan.created).toLocaleString()}
          </Typography>
        </Grid>
      </Grid>

      {isAdmin && (
        <Grid
          container
          mt={theme.spacing(1)}
          direction="row"
          alignItems="flex-start"
        >
          <Grid item xs={5}>
            <Typography component="p" variant="body2">
              Eigentümer
            </Typography>
          </Grid>
          <Grid item xs={7}>
            <Typography component="p" variant="body2">
              <b>{customer.email}</b>
            </Typography>
            <Grid mt={theme.spacing(1)}>
              <Button
                variant="outlined"
                color="primary"
                onClick={() => {
                  setShowOwnerInput(true)
                }}
              >
                Ändern
              </Button>
            </Grid>

            {showOwnerInput && (
              <Grid mt={theme.spacing(2)}>
                <TextField
                  sx={{
                    '& .MuiInputBase-input': {
                      height: 22,
                      marginLeft: 0,
                      marginRight: '5px',
                      fontSize: 13,
                    },
                  }}
                  InputLabelProps={{shrink: true}}
                  size={"small"}
                  margin="dense"
                  variant="outlined"
                  placeholder={"Neue Eigentümer E-Mail"}
                  value={owner}
                  onChange={e => {
                    setOwner(e.target.value)
                  }}
                />
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={() => setOwnerButtonOnClick()}
                  disabled={owner.length < 1 || owner === plan.owner}
                >
                  Speichern
                </Button>
              </Grid>
            )}
          </Grid>
        </Grid>
      )}

      <Grid container mt={theme.spacing(1)} direction="row">
        <Grid item xs={5}>
          <Typography component="p" variant="body2">
            Schachtbezeichnung
          </Typography>
        </Grid>
        <Grid item xs={7}>
          <Typography
            display="block"
            component="p"
            variant="body2"
            sx={{ cursor: "pointer" }}
            onClick={_ => {
              navigator.clipboard.writeText(plan.description)
              setSnackbar("Schachtbezeichnung in Zwischenablage kopiert")
            }}
          >
            {plan.description}
          </Typography>
        </Grid>
      </Grid>
      <Grid
        item
        marginTop={theme.spacing(2)}
        display="flex"
        justifyContent="center"
      >
        <ActionButtons plan={plan} project={project}></ActionButtons>
      </Grid>
    </Paper>
  )
}
