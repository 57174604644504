import React from 'react'

import {Paper, useTheme} from "@mui/material";

import {whiteBackgroundColor} from "../../../constants"
import Grid from "@mui/material/Grid"

import {PlanDescriptionSection} from "./PlanDescriptionSection"
import {CompositionElevationViewSection} from "./CompositionElevationViewSection"
import {useSelector} from "react-redux"
import {LidGroundViewSection} from "./LidGroundViewSection"
import {FloorGroundViewSection} from "./FloorGroundViewSection"
import {IntermediateCeilingGroundViewSection} from "./IntermediateCeilingGroundViewSection"
import {getManhole} from "../../domain/lid"
import validate from "../../../validation/validation";
import {Alerts} from "../../Alerts"

export const VerificationStep = () => {
  const theme = useTheme()
  const plan = useSelector(state => state.plan)
  const project = useSelector(state => state.project)
  const errors = validate(plan, project)

  return (
    <div>
      <Paper square={true} sx={{
        background: whiteBackgroundColor,
        padding: theme.spacing(6)
      }}>
        <Grid container spacing={6}>
          {
            errors && errors.length > 0 &&
            <Alerts errors={errors}/>
          }
          <Grid item xs={12}>
            <PlanDescriptionSection/>
          </Grid>
          {
            !!plan.lid &&
            !(plan.lid.collar === 'without' && !getManhole(plan)) &&
            <Grid item xs={12}>
              <LidGroundViewSection/>
            </Grid>
          }
          {
            !!plan.intermediateCeiling && !plan.lid &&
            <Grid item xs={12}>
              <IntermediateCeilingGroundViewSection/>
            </Grid>
          }
          {
            !!plan.floor &&
            <Grid item xs={12}>
              <FloorGroundViewSection/>
            </Grid>
          }
          {
            (!!plan.lid || !!plan.intermediateCeiling || !!plan.vault || !!plan.floor) &&
            <React.Fragment>
              <Grid item xs={12}>
                <CompositionElevationViewSection wallIndex={0}/>
              </Grid>
              <Grid item xs={12}>
                <CompositionElevationViewSection wallIndex={1}/>
              </Grid>
              <Grid item xs={12}>
                <CompositionElevationViewSection wallIndex={2}/>
              </Grid>
              <Grid item xs={12}>
                <CompositionElevationViewSection wallIndex={3}/>
              </Grid>
            </React.Fragment>
          }
        </Grid>
      </Paper>
    </div>
  )
}

