import React from "react"
import {Alert, Divider, Grid, Paper, Snackbar, Typography, useTheme} from "@mui/material"

export const CustomerPanel = props => {
  const {customer, project, emailSubject} = props
  const theme = useTheme()
  const [snackbar, setSnackbar] = React.useState("");

  const clipboardAddress = () =>
    [
      customer.company,
      [customer.lastname, customer.firstname].filter(e => e !== null && e.length > 0).join(" "),
      customer.address,
      [customer.zip, customer.city].filter(e => e !== null && e.length > 0).join(" ")
    ]
      .filter(e => e !== null && e.length > 0)
      .join("\n")

  const handleSnackBarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbar("");
  };
  return (
    <Paper square={true} sx={{
      background: "white",
      padding: theme.spacing(4)
    }}>
      <Snackbar
        open={snackbar.length > 0}
        autoHideDuration={3000}
        anchorOrigin={{vertical: 'top', horizontal: 'right'}}
        onClose={handleSnackBarClose}
      >
        <Alert severity="info" sx={{width: '100%'}}>
          {snackbar}
        </Alert>
      </Snackbar>
      <Typography component="h1" variant="h5">
        Kunde
      </Typography>
      <Divider style={{background: "black"}}/>
      <Typography
        mt={theme.spacing(4)}
        component="p"
        variant="body2"
        sx={{ cursor: "pointer" }}
        onClick={_ => {
          navigator.clipboard.writeText(clipboardAddress())
          setSnackbar("Adresse in Zwischenablage kopiert")
        }}
      >
        <b>{customer.company}</b>
      </Typography>
      <Typography
        component="p"
        variant="body2"
        sx={{ cursor: "pointer"}}
        onClick={_ => {
          navigator.clipboard.writeText(clipboardAddress())
          setSnackbar("Adresse in Zwischenablage kopiert")
        }}
      >
        {`${customer.lastname} ${customer.firstname}`}
      </Typography>
      <Typography
        component="p"
        variant="body2"
        sx={{ cursor: "pointer"}}
        onClick={_ => {
          navigator.clipboard.writeText(clipboardAddress())
          setSnackbar("Adresse in Zwischenablage kopiert")
        }}
      >
        {customer.address}
      </Typography>
      <Typography
        component="p"
        variant="body2"
        sx={{ cursor: "pointer"}}
        onClick={_ => {
          navigator.clipboard.writeText(clipboardAddress())
          setSnackbar("Adresse in Zwischenablage kopiert")
        }}
      >
        {`${customer.zip} ${customer.city}`}
      </Typography>
      <Typography mt={theme.spacing(2)} component="p" variant="body2">
        {customer.phone}
      </Typography>
      <Typography
        component="p"
        variant="body2"
        sx={{ cursor: "pointer"}}
        onClick={() => window.location = `mailto:${customer.email}?subject=${emailSubject}`}
      >
        {customer.email}
      </Typography>

      {!!project && (
        <React.Fragment>
          <Grid container mt={theme.spacing(3)} direction="row">
            <Grid item xs={5}>
              <Typography component="p" variant="body2">
                Kommision
              </Typography>
            </Grid>
            <Grid item xs={7}>
              <Typography component="p" variant="body2" fontWeight="800">
                <b>{project.commission}</b>
              </Typography>
            </Grid>
          </Grid>

          <Grid container mt={theme.spacing(1)} direction="row">
            <Grid item xs={5}>
              <Typography component="p" variant="body2">
                Ihre Bestell-/Auftrag Nr
              </Typography>
            </Grid>
            <Grid item xs={7}>
              <Typography component="p" variant="body2" fontWeight="800">
                <b>{project.usersOrderNumber}</b>
              </Typography>
            </Grid>
          </Grid>
        </React.Fragment>
      )}
    </Paper>
  )
}
