import React, {forwardRef, useCallback, useEffect, useImperativeHandle} from "react"
import {useDispatch, useSelector} from "react-redux"

import {Layer, Rect, Stage, Transformer} from "react-konva"

import {createPart} from "../GroundPlan/part"
import {
  createHorizontalMeasurements,
  createVerticalMeasurements,
} from "../../measurement"
import {
  getPartialXMeasurements,
  getPartialYMeasurements
} from "../../configurationPlanMeasurement"
import {createVerticallyCenteredGrid} from "../grid"
import {AutoResizeDiv} from "../../AutoResizeDiv"
import {renderToolbar} from "./intermediateCeilingPlanToolbar"
import {renderGroupElements as renderBlockOutGroupElements} from "../../Element/blockOutRenderFactory"
import {renderGroupElements as renderCircularBlockOutGroupElements} from "../../Element/circularBlockOutRenderFactory";
import {createRectangularElementCrossHairLayer} from "../../Element/rectangularElementCrossHairs"
import {renderRectangularPlanElement} from "../../Element/rectangularElementFunctions";
import {createIntermediateCeilingDomainFunctions} from "../../../domain/intermediateCeilingDomainFunctions";
import {createCircularElementCrossHairLayer} from "../../Element/circularElementCrossHairs";
import {renderCircularPlanElement} from "../../Element/circularElementFunctions";
import {createGroundPlanLayout} from "../planLayout";
import {useCopyPaste} from "../CopyPasteContext";
import uuid from "react-uuid";
import {selectionActions} from "../../../../actions";
import {useConfigurationPlanInteractions} from "../configurationPlanInteractions";

const Plan = forwardRef((props, ref) => {
  const {clientSize} = props
  const dispatch = useDispatch()
  const plan = useSelector(state => state.plan)
  const selectedIds = useSelector(state => state.selection)
  const layout = createGroundPlanLayout(clientSize, plan)
  const domainFunctions = createIntermediateCeilingDomainFunctions(dispatch, plan)
  const {copy, paste} = useCopyPaste(domainFunctions)

  const clearSelection = useCallback(() => {
    dispatch(selectionActions.clearSelected());
  }, [dispatch]);

  useEffect(() => {
    clearSelection();
  }, [clearSelection]);

  const getHorizontalMeasurementStops = (layout, circularBlockOuts, blockOuts) => {
    const wallWidth = (layout.part.realWidth - layout.configArea.realWidth) / 2
    const outerStops = [wallWidth, layout.configArea.realWidth, wallWidth]
    const innerStops = getPartialXMeasurements(circularBlockOuts, [], blockOuts, layout.configArea.realWidth)
    const innerOffset = wallWidth + (blockOuts.some(b => b.x < 0) ? Math.min(...blockOuts.map(b => b.x)) : 0)

    return innerStops.length > 1
      ? {stops: [innerStops, outerStops], offsets: [innerOffset, 0]}
      : {stops: [outerStops], offsets: [0]}
  }

  const getVerticalMeasurementStops = (layout, circularBlockOuts, blockOuts) => {
    const wallWidth = (layout.part.realHeight - layout.configArea.realHeight) / 2
    const outerStops = [wallWidth, layout.configArea.realHeight, wallWidth]
    const innerStops = getPartialYMeasurements(circularBlockOuts, [], blockOuts, layout.configArea.realHeight)
    const getClearMeasureTop = () => layout.configArea.realHeight
    const getMaxBlockOutTop = () => blockOuts.length > 0 ? Math.max(...blockOuts.map(b => b.y + b.height)) : 0
    const getInnerOffset = () => wallWidth
      - (
        getMaxBlockOutTop() > getClearMeasureTop()
          ? getMaxBlockOutTop() - getClearMeasureTop()
          : 0
      )

    return innerStops.length > 1
      ? {stops: [outerStops, innerStops], offsets: [0, getInnerOffset()]}
      : {stops: [outerStops], offsets: [0]}
  }

  const renderMeasurements = (layout, circularBlockOuts, blockOuts) => {
    const horizontalMeasurementStops = getHorizontalMeasurementStops(layout, circularBlockOuts, blockOuts)
    const verticalMeasurementStops = getVerticalMeasurementStops(layout, circularBlockOuts, blockOuts)

    return (
      <React.Fragment>
        {
          createHorizontalMeasurements(
            layout.horizontalMeasurements,
            horizontalMeasurementStops.stops,
            horizontalMeasurementStops.offsets
          )
        }
        {
          createVerticalMeasurements(
            layout.verticalMeasurements,
            verticalMeasurementStops.stops,
            verticalMeasurementStops.offsets
          )
        }
      </React.Fragment>
    )
  }

  const handleCopy = () => {
    const toCopy = {
      context: 'intermediateCeiling',
      intermediateCeilingBlockOuts:
        plan.intermediateCeiling.blockOuts.filter(blockOut => selectedIds.includes(blockOut.id)),
      circularIntermediateCeilingBlockOuts:
        plan.intermediateCeiling.circularBlockOuts.filter(blockOut => selectedIds.includes(blockOut.id))
    }
    copy(toCopy);
  };

  const handlePaste = () => {
    const pastedObject = paste();

    if (!pastedObject || pastedObject.context !== 'intermediateCeiling') {
      return;
    }
    const translateY = (elements, y) =>
      Array.isArray(elements)
        ? elements.map(element => {
          return {...element, y: element.y + y}
        })
        : null
    const translateYBy = -20;
    const translated = ({
      intermediateCeilingBlockOuts: translateY(pastedObject.intermediateCeilingBlockOuts, translateYBy),
      circularIntermediateCeilingBlockOuts: translateY(pastedObject.circularIntermediateCeilingBlockOuts, translateYBy)
    })
    const pasteElements = (elements, addFunc) =>
      Array.isArray(elements)
        ? elements.map(element => {
          const id = uuid();

          addFunc({...element, id: id});
          return id;
        })
        : []

    dispatch(selectionActions.setSelected([
      ...pasteElements(translated.intermediateCeilingBlockOuts, domainFunctions.blockOutFunctions.add),
      ...pasteElements(translated.circularIntermediateCeilingBlockOuts, domainFunctions.circularBlockOutFunctions.add)
    ]));
    copy({...pastedObject, ...translated});
  };

  const handleDelete = () => {
    const del = (elements, functions) =>
      elements.filter(el => selectedIds.includes(el.id)).forEach(el => functions.remove(el.id))

    del(plan.intermediateCeiling.blockOuts, domainFunctions.blockOutFunctions);
    del(plan.intermediateCeiling.circularBlockOuts, domainFunctions.circularBlockOutFunctions);
    dispatch(selectionActions.clearSelected)
  };

  useImperativeHandle(ref, () => ({
    delete: () => handleDelete(),
    copy: () => handleCopy(),
    paste: () => handlePaste()
  }));

  const {
    transformerRef,
    layerRef,
    selectionRectRef,
    checkDeselect,
    onMouseDown,
    onMouseMove,
    onMouseUp,
    onClickTap,
    onDblClickTap,
    onDragStart,
    handleTransformerDragStart,
    handleTransformerDragMove,
    handleTransformerDragEnd,
  } = useConfigurationPlanInteractions(ref, props, layout, handleDelete);

  return (
    <div>
      <React.Fragment/>
      {!isNaN(layout.scale) &&
        <Stage
          name={"stage"}
          width={clientSize.width}
          height={layout.overall.screenHeight}
          onMouseDown={onMouseDown}
          onMouseUp={onMouseUp}
          onMouseMove={onMouseMove}
          onTouchStart={checkDeselect}
          onClick={onClickTap}
          onTap={onClickTap}
          onDblClick={onDblClickTap}
          onDblTap={onDblClickTap}
          onDragStart={onDragStart}
        >
          <Layer ref={layerRef}>
            {createPart(layout.part, layout.configArea)}
            {createVerticallyCenteredGrid(layout.configArea, layout.configAreaRealWorldTransform)}
            {renderMeasurements(layout, plan.intermediateCeiling.circularBlockOuts, plan.intermediateCeiling.blockOuts)}
            {
              plan.intermediateCeiling.blockOuts.map(blockOut => renderRectangularPlanElement(
                createIntermediateCeilingDomainFunctions(dispatch, plan).blockOutFunctions,
                layout.configArea,
                blockOut,
                (screenRect, blockOut) => renderBlockOutGroupElements(
                  screenRect,
                  blockOut
                )
              ))
            }
            {
              plan.intermediateCeiling.circularBlockOuts.map(blockOut => renderCircularPlanElement(
                createIntermediateCeilingDomainFunctions(dispatch, plan).circularBlockOutFunctions,
                layout.configArea,
                blockOut,
                (screenCircle, blockOut) => renderCircularBlockOutGroupElements(
                  screenCircle,
                  blockOut
                )
              ))
            }
            {
              renderToolbar(
                createIntermediateCeilingDomainFunctions(dispatch, plan),
                layout.toolbar,
                layout.configArea
              )
            }
            <Transformer
              ref={transformerRef}
              resizeEnabled={false}
              rotateEnabled={false}
              draggable={true}
              onDragStart={handleTransformerDragStart}
              onDragMove={handleTransformerDragMove}
              onDragEnd={handleTransformerDragEnd}
            />
            <Rect fill="rgba(0,0,255,0.5)" ref={selectionRectRef}/>
          </Layer>
          {createRectangularElementCrossHairLayer()}
          {createCircularElementCrossHairLayer()}
        </Stage>
      }
    </div>
  );
})

export const IntermediateCeilingPlan = props => {
  const {childRef} = props

  return (
    <AutoResizeDiv>
      <Plan ref={childRef} {...props}/>
    </AutoResizeDiv>
  )
}
