import {billingApiActionTypes} from "../constants"
import {projectService} from "../services"

const resetBillings = () => {
  return {
    type: billingApiActionTypes.resetBillings,
  }
}

const readBillings = (limit = 5, searchTerm = '') => {
  const request = () => {
    return {type: billingApiActionTypes.readBillingsRequest}
  }
  const success = result => {
    return {type: billingApiActionTypes.readBillingsSuccess, data: result}
  }
  const failure = error => {
    return {type: billingApiActionTypes.readBillingsFailure, error}
  }

  return dispatch => {
    dispatch(request())
    projectService.readBillingAddresses(limit, searchTerm)
      .then(
        result => dispatch(success(result)),
        error => dispatch(failure(error))
      )
  }
}

export const billingApiActions = {
  resetBillings,
  readBillings
}
