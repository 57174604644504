import React from "react"
import {Alert, Divider, Paper, Snackbar, Typography, useTheme} from "@mui/material"

export const BillingPanel = props => {
  const {billing, emailSubject} = props
  const theme = useTheme()
  const [snackbar, setSnackbar] = React.useState("");

  const clipboardAddress = () =>
    [
      billing.company,
      billing.name,
      billing.street,
      [billing.zip, billing.city].filter(e => e !== null && e.length > 0).join(" ")
    ]
      .filter(e => e !== null && e.length > 0)
      .join("\n")

  const handleSnackBarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbar("");
  };
  return (
    <Paper square={true} sx={{
      background: "white",
      padding: theme.spacing(4)
    }}>
      <Snackbar
        open={snackbar.length > 0}
        autoHideDuration={3000}
        anchorOrigin={{vertical: 'top', horizontal: 'right'}}
        onClose={handleSnackBarClose}
      >
        <Alert severity="info" sx={{width: '100%'}}>
          {snackbar}
        </Alert>
      </Snackbar>
      <Typography component="h1" variant="h5">
        Rechnungsadresse
      </Typography>
      <Divider style={{background: "black"}}/>
      <Typography
        mt={theme.spacing(4)}
        component="p"
        variant="body2"
        sx={{ cursor: "pointer" }}
        onClick={_ => {
          navigator.clipboard.writeText(clipboardAddress())
          setSnackbar("Adresse in Zwischenablage kopiert")
        }}
      >
        <b>{billing.company}</b>
      </Typography>
      <Typography
        component="p"
        variant="body2"
        sx={{ cursor: "pointer" }}
        onClick={_ => {
          navigator.clipboard.writeText(clipboardAddress())
          setSnackbar("Adresse in Zwischenablage kopiert")
        }}
      >
        {`${billing.name}`}
      </Typography>
      <Typography
        component="p"
        variant="body2"
        sx={{ cursor: "pointer" }}
        onClick={_ => {
          navigator.clipboard.writeText(clipboardAddress())
          setSnackbar("Adresse in Zwischenablage kopiert")
        }}
      >
        {billing.street}
      </Typography>
      <Typography
        component="p"
        variant="body2"
        sx={{ cursor: "pointer" }}
        onClick={_ => {
          navigator.clipboard.writeText(clipboardAddress())
          setSnackbar("Adresse in Zwischenablage kopiert")
        }}
      >
        {`${billing.zip} ${billing.city}`}
      </Typography>
      <Typography mt={theme.spacing(2)} component="p" variant="body2">
        {billing.phone}
      </Typography>
      <Typography
        component="p"
        variant="body2"
        sx={{ cursor: "pointer" }}
        onClick={() => window.location = `mailto:${billing.email}?subject=${emailSubject}`}
      >
        {billing.email}
      </Typography>
    </Paper>
  )
}
