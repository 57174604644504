import {planActionTypes as actionTypes} from "../constants";
import {isFloorThicknessValid} from "../validation/planFormValidation";

const initialState = {
  thickness: 15,
  drainages: []
}

const drainagesReducer = (state, action) => {
  switch (action.type) {
    case actionTypes.addFloorDrainage:
      return [...state, action.data]

    case actionTypes.removeFloorDrainage:
      return state.filter(drainage => drainage.id !== action.data)

    case actionTypes.moveFloorDrainage:
      return state.map(drainage => {
        if (action.data.id !== drainage.id) {
          return drainage;
        }
        return {...drainage, x: action.data.x, y: action.data.y}
      });

    default:
      return state
  }
}

export const floorReducer = (state, action) => {
  switch (action.type) {
    case actionTypes.setFloor:
      return state || initialState

    case actionTypes.unsetFloor:
      return undefined;

    case actionTypes.setFloorThickness:
      if (state && Number.isInteger(action.data) && isFloorThicknessValid(action.data)) {
        return {...state, thickness: action.data}
      }
      return state

    default:
  }
  if (!state) return state
  const newDrainages = drainagesReducer(state.drainages, action)

  if (state.drainages === newDrainages) return state
  return {...state, drainages: newDrainages}
}
