import React, {useEffect} from "react"
import {Route, Redirect, Switch} from "react-router-dom"
import "./css/style.css"
import { ThemeProvider, StyledEngineProvider, createTheme } from '@mui/material/styles';

import {Auth} from './containers/Auth'
import {Plans, PlanDetails} from './containers/Plans'
import {Projects, ProjectDetails} from './containers/Projects'
import {Step} from "./containers/Steps"
import {ApiRequest} from "./containers/ApiRequest"
import {error_color, primary_orange, secondary_orange} from "./constants"
import {GuardedRoute} from "./containers/GuardedRoute"
import {useDispatch, useSelector} from "react-redux"
import {isLoginRequired} from "./helpers/authHelper"
import {planActions, planApiActions, projectApiActions} from "./actions";
import {reset as resetProject} from "./reducers/projectReducer"
import {frontendVersion} from "./constants/frontendVersion";
import {frontendVersionService} from "./services/frontendVersionService";

const theme = createTheme({
  palette: {
    primary: {main: primary_orange},
    secondary: {main: secondary_orange},
    error: {main: error_color}
  },
  status: {
    danger: 'red',
  },
  typography: {
    button: {
      textTransform: "none"
    }
  }
});

function App()  {
  const authentication = useSelector(state => state.authentication)
  const planVersion = useSelector(state => state.plan.version)
  const dispatch = useDispatch()

  useEffect(() => {
      frontendVersionService.readVersion(
      ).then(
        r => {
          console.log("local version is", frontendVersion, "server version is", r.version)
          if (r.version !== frontendVersion) {
            console.log(`new version available:${r.version}, reloading frontend`)
            window.location.reload(true)
          }
        }
      )
    },
    []
  )
  if (planVersion > frontendVersion || planVersion < "2.18") dispatch(planActions.reset())

  return (
    <StyledEngineProvider injectFirst>
      (<ThemeProvider theme={theme}>
        <Switch>
          <Route exact path="/auth" component={Auth}/>
          <Route exact path="/registration" component={Auth}/>
          <Route exact path="/emailverification" component={Auth}/>
          <Route exact path="/login" component={Auth}/>
          <Route exact path="/resetpassword" component={Auth}/>
          <Route exact path="/changepassword" component={Auth}/>
          <GuardedRoute
            exact path="/plans"
            component={Plans}
            auth={() => !isLoginRequired(authentication)}
            navigateTo={"/plans"}
          />
          <GuardedRoute
            exact path="/plans/:id"
            component={PlanDetails}
            auth={() => !isLoginRequired(authentication)}
            navigateTo={"/plans/:id"}
          />
          <GuardedRoute
            exact path="/projects"
            component={Projects}
            auth={() => !isLoginRequired(authentication)}
            navigateTo={"/projects"}
          />
          <GuardedRoute
            exact path="/projects/:id"
            component={ProjectDetails}
            auth={() => !isLoginRequired(authentication)}
            navigateTo={"/projects/:id"}
          />
          <Route path="/apirequest" component={ApiRequest}/>
          <Route path={"/step"} component={Step}/>
          <Route path="/reset" render={() => {
            dispatch(planActions.reset())
            dispatch(resetProject())
            dispatch(projectApiActions.resetProjects())
            dispatch(planApiActions.resetPlans())

            return <Redirect to={"/step/1"}/>
          }}/>
          <Route path="/" render={() => {
            return <Redirect to={"/step/1"}/>
          }}/>
        </Switch>
      </ThemeProvider>)
    </StyledEngineProvider>
  );
}
export default App
