import {useRef, useEffect} from "react";

export const useForwardPlanStepKeyboardShortcuts = () => {
  const childRef = useRef(null);

  const handleKeyDown = event => {
    if (childRef.current) {
      if (event.key === 'Delete') childRef.current.delete();
      if ((event.ctrlKey || event.metaKey) && event.key === 'c') childRef.current.copy();
      if ((event.ctrlKey || event.metaKey) && event.key === 'v') childRef.current.paste();
    }
  }

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    }
  }, [])

  return {childRef}
}

