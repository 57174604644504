import React from "react";

export const DimensionsIcon = props => {
  const width = props.width * 0.9
  const height = props.height * 0.9
  const getWallWidth = () => 10
  const getMaxWidth = () => 320
  const getCenterX = () => getMaxWidth() / 2
  const getCenterY = () => 120

  const getInnerRectX = () => getCenterX() - width / 2
  const getInnerRectY = () => getCenterY() - height / 2
  const getInnerRectWidth = () => width
  const getInnerRectHeight = () => height

  const getOuterRectX = () => getInnerRectX() - getWallWidth()
  const getOuterRectY = () => getInnerRectY() - getWallWidth()
  const getOuterRectWidth = () => getInnerRectWidth() + 2 * getWallWidth()
  const getOuterRectHeight = () => getInnerRectHeight() + 2 * getWallWidth()

  return (
    <svg version="1.1" viewBox="0 0 320 320" xmlns="http://www.w3.org/2000/svg">
      <rect
        x={getOuterRectX()}
        y={getOuterRectY()}
        width={getOuterRectWidth()}
        height={getOuterRectHeight()}
        fillOpacity="0"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        style={{paintOrder:"markers fill stroke"}}
      />
      <rect
        x={getInnerRectX()}
        y={getInnerRectY()}
        width={getInnerRectWidth()}
        height={getInnerRectHeight()}
        fillOpacity="0"
        stroke="#000"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        style={{paintOrder:"markers fill stroke"}}
      />
    </svg>
  )
}
