import React, {useEffect, useState} from "react"

import {
  CircularProgress,
  Container,
  Grid,
  Paper,
  Typography,
  useTheme
} from "@mui/material"
import {useDispatch, useSelector} from "react-redux"

import {grayBackgroundColor, orangeButtonStyle} from "../../constants"
import {planApiActions} from "../../actions"
import {Header} from "../Header"
import Button from "@mui/material/Button"
import {AlertDialog} from "../AlertDialog"
import {PlanListItem} from "./PlanListItem"
import {Search} from "../Search"

const TitleGridItem = props => {
  const {searchTerm, onSearchTermChange, onSearchTermClear, onSearchEnter} =
    props
  const theme = useTheme()

  return (
    <Grid item xs={12}>
      <Grid
        container
        direction="row"
        justifyContent="space-between"
        alignItems="flex-end"
        style={{marginBottom: theme.spacing(1)}}
      >
        <Typography xs={5} component="h1" variant="h4">
          Persönliche Pläne
        </Typography>
        <Search
          searchTerm={searchTerm}
          onSearchTermChange={onSearchTermChange}
          onSearchTermClear={onSearchTermClear}
          onSearchEnter={onSearchEnter}
        />
      </Grid>
      <hr/>
    </Grid>
  )
}

export const Plans = () => {
  const theme = useTheme()
  const dispatch = useDispatch()
  const plans = useSelector(state => state.plans)
  const apiRequestState = useSelector(state => state.apiRequestState)
  const [filter, setFilter] = useState("")
  const [resultCount, setResultCount] = useState(15)
  const [readErrorAlertOpen, setReadErrorAlertOpen] = useState(true)
  useEffect(
    () => dispatch(planApiActions.readPlans(resultCount, filter)),
    [dispatch, resultCount, filter]
  )
  const hasReadPlanError = () =>
    !!apiRequestState &&
    apiRequestState.request === "read_plan" &&
    apiRequestState.state === "failed"
  const hasCreateArmouringDocsError = () =>
    !!apiRequestState &&
    apiRequestState.request === "create_armouring_documents" &&
    apiRequestState.state === "failed"

  return (
    <Container
      maxWidth="lg"
      style={{paddingBottom: theme.spacing(4), minWidth: "1200px"}}
    >
      <Grid container spacing={1}>
        <Grid item xs={12}>
          <Header/>
        </Grid>
        <Grid item xs={12}>
          <Paper square={true} sx={{
            background: grayBackgroundColor,
            padding: theme.spacing(6)
          }}>
            {plans.fetching && (
              <Grid container direction="column" alignItems="center">
                <Grid item>
                  <CircularProgress style={{justifySelf: "center"}}/>
                </Grid>
              </Grid>
            )}
            {hasReadPlanError() && (
              <AlertDialog
                open={readErrorAlertOpen}
                setOpen={setReadErrorAlertOpen}
                title="Als Vorlage öffnen ist fehlgeschlagen"
                onConfirm={() => {
                  setReadErrorAlertOpen(true)
                  dispatch(planApiActions.readPlans(resultCount, filter))
                }}
              >
                <Typography component="p" variant="body2">
                  Der Plan konnte leider nicht als Vorlage geöffnet werden.
                  Bitte versuchen Sie es in Kürze nochmals oder nehmen mit uns
                  Kontakt auf.
                </Typography>
              </AlertDialog>
            )}
            {hasCreateArmouringDocsError() && (
              <AlertDialog
                open={readErrorAlertOpen}
                setOpen={setReadErrorAlertOpen}
                title="Armierung generieren ist fehlgeschlagen"
                onConfirm={() => {
                  setReadErrorAlertOpen(true)
                  dispatch(planApiActions.readPlans(resultCount, filter))
                }}
              >
                <Typography component="p" variant="body2">
                  Die Armierungs-Dokumente konnten nicht generiert werden. Bitte
                  versuchen Sie es in Kürze nochmals oder nehmen mit uns Kontakt
                  auf.
                </Typography>
              </AlertDialog>
            )}
            {plans.error && (
              <Typography
                component="p"
                variant="body2"
                color="error"
                style={{marginTop: theme.spacing(2)}}
              >
                {plans.error.toString()}
              </Typography>
            )}
            {Array.isArray(plans) && (
              <Grid container spacing={4}>
                <TitleGridItem
                  searchTerm={filter}
                  onSearchTermChange={searchTerm => setFilter(searchTerm)}
                  onSearchTermClear={() => {
                    setFilter("")
                    setResultCount(15)
                    dispatch(planApiActions.readPlans(15, ""))
                  }}
                  onSearchEnter={searchTerm => {
                    setFilter(searchTerm)
                    setResultCount(15)
                  }}
                />
                {plans.map(plan => {
                  return (
                    <PlanListItem
                      plan={plan}
                      key={"plan_item_" + plan.id}
                      isDeleteEnabled
                    />
                  )
                })}
              </Grid>
            )}

            <Grid container justifyContent={"center"}>
              <Grid item xs={2}/>
              <Grid item xs={8}>
                <Grid container justifyContent={"center"}>
                  {Array.isArray(plans) && plans.length >= resultCount && (
                    <Button
                      sx={orangeButtonStyle}
                      style={{marginTop: theme.spacing(6)}}
                      color="primary"
                      variant="contained"
                      onClick={() => {
                        setResultCount(resultCount + 15)
                      }}
                    >
                      Mehr anzeigen
                    </Button>
                  )}
                </Grid>
              </Grid>

              <Grid item xs={2}>
                <Grid container justifyContent={"flex-end"}>
                  <Button
                    style={{marginTop: theme.spacing(6)}}
                    color="primary"
                    onClick={() => {
                      window.scrollTo(0, 0)
                    }}
                  >
                    Nach oben
                  </Button>
                </Grid>
              </Grid>
            </Grid>
          </Paper>
        </Grid>
      </Grid>
    </Container>
  )
}
