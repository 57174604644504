import React, { createContext, useState, useContext } from 'react';

const CopyPasteContext = createContext();

// Custom hook for using the context
export const useCopyPaste = () => useContext(CopyPasteContext);

// Context provider component
export const CopyPasteProvider = ({ children }) => {
  const [copiedData, setCopiedData] = useState(null);

  const copy = (data) => {
    setCopiedData(data);
  };

  const paste = () => {
    return copiedData;
  };

  return (
    <CopyPasteContext.Provider value={{ copy, paste }}>
      {children}
    </CopyPasteContext.Provider>
  );
};