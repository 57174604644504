import {useRef, useEffect, useCallback} from "react";
import {useDispatch, useSelector} from "react-redux";
import Konva from "konva";
import {selectionActions} from "../../../actions";

export const useConfigurationPlanInteractions = (ref, props, layout, handleDelete) => {
  const dispatch = useDispatch();
  const selectedIds = useSelector(state => state.selection);
  const transformerRef = useRef();
  const layerRef = useRef();
  const initialPositionsRef = useRef({});
  const initialTransformerPositionRef = useRef({});
  const isTransformerDraggingRef = useRef(false);
  const mouseDownElementRef = useRef();
  const selectionRectRef = useRef();
  const selection = useRef({
    visible: false,
    x1: 0,
    y1: 0,
    x2: 0,
    y2: 0
  });

  const syncTransformer = useCallback(() => {
    const nodes = selectedIds.map((id) => {
      return layerRef.current?.findOne("#" + id);
    }).filter(n => !!n);
    if (!!transformerRef && !!transformerRef.current) transformerRef.current.nodes(nodes);
  }, [selectedIds]);

  useEffect(() => {
    syncTransformer();
  }, [selectedIds, syncTransformer]);

  const checkDeselect = (e) => {
    const planElement = e.target.findAncestor(".plan_element");
    const clickedOnEmpty = planElement === undefined;

    if (clickedOnEmpty) {
      dispatch(selectionActions.clearSelected());
    }
  };

  const updateSelectionRect = () => {
    const node = selectionRectRef.current;
    node.setAttrs({
      visible: selection.current.visible,
      x: Math.min(selection.current.x1, selection.current.x2),
      y: Math.min(selection.current.y1, selection.current.y2),
      width: Math.abs(selection.current.x1 - selection.current.x2),
      height: Math.abs(selection.current.y1 - selection.current.y2),
      fill: "rgba(0, 161, 255, 0.3)"
    });
    node.getLayer().batchDraw();
  };

  const onMouseDown = (e) => {
    const pos = e.target.getStage().getPointerPosition();

    selection.current.x1 = pos.x;
    selection.current.y1 = pos.y;
    selection.current.x2 = pos.x;
    selection.current.y2 = pos.y;
    const planElement = e.target.findAncestor(".plan_element");
    const transformer = e.target.findAncestor("Transformer");
    const isWithinConfigArea = pos.x > layout.configArea.screenX
      && pos.x < layout.configArea.screenX + layout.configArea.screenWidth
      && pos.y > layout.configArea.screenY
      && pos.y < layout.configArea.screenY + layout.configArea.screenHeight;

    if (!!planElement) {
      mouseDownElementRef.current = planElement;
    }
    if (!isWithinConfigArea || planElement || transformer) {
      return;
    }
    selection.current.visible = true;
    updateSelectionRect();
  };

  const onMouseMove = (e) => {
    if (!selection.current.visible) {
      return;
    }
    const pos = e.target.getStage().getPointerPosition();
    const withinConfigArea = {
      x: Math.max(Math.min(pos.x, layout.configArea.screenX + layout.configArea.screenWidth), layout.configArea.screenX),
      y: Math.max(Math.min(pos.y, layout.configArea.screenY + layout.configArea.screenHeight), layout.configArea.screenY),
    };

    selection.current.x2 = withinConfigArea.x;
    selection.current.y2 = withinConfigArea.y;
    updateSelectionRect();
  };

  const onMouseUp = () => {
    mouseDownElementRef.current = null;
    selection.current.visible = false;
    const {x1, x2, y1, y2} = selection.current;
    const moved = x1 !== x2 || y1 !== y2;
    if (!moved) {
      updateSelectionRect();
      return;
    }
    const selBox = selectionRectRef.current.getClientRect();

    const elements = [];
    layerRef.current?.find(".plan_element").forEach((elementNode) => {
      const elBox = elementNode.children[0].getClientRect();

      if (Konva.Util.haveIntersection(selBox, elBox)) {
        elements.push(elementNode);
      }
    });

    const ids = elements.map((el) => el.id());

    dispatch(selectionActions.setSelected(ids));
    updateSelectionRect();
  };

  const onClickTap = (e) => {
    const {x1, x2, y1, y2} = selection.current;
    const moved = x1 !== x2 || y1 !== y2;
    if (moved) {
      return;
    }
    const plan_element = e.target.findAncestor(".plan_element");
    if (!plan_element) {
      dispatch(selectionActions.clearSelected());
      return;
    }
    const id = plan_element.id();
    const metaPressed = e.evt.shiftKey || e.evt.ctrlKey || e.evt.metaKey;
    const isSelected = selectedIds.indexOf(id) >= 0;

    if (!metaPressed && !isSelected) {
      dispatch(selectionActions.setSelected([id]));
    } else if (metaPressed && isSelected) {
      dispatch(selectionActions.removeSelected(id));
    } else if (metaPressed && !isSelected) {
      dispatch(selectionActions.addSelected(id));
    }
  };

  const onDblClickTap = () => {
    handleDelete();
    dispatch(selectionActions.clearSelected());
  };

  const onDragStart = (e) => {
    if (isTransformerDraggingRef.current) {
      return;
    }
    transformerRef.current.nodes([e.target]);
    const targetId = e.target.name() === 'plan_element' ? e.target.id() : '';

    if (targetId && selectedIds.indexOf(targetId) < 0) {
      dispatch(selectionActions.setSelected([targetId]));
    }
    transformerRef.current.startDrag();
  };

  const handleTransformerDragStart = () => {
    isTransformerDraggingRef.current = true;
    initialPositionsRef.current = {};
    const transformer = transformerRef.current;

    initialTransformerPositionRef.current = {
      x: transformer.x(),
      y: transformer.y(),
    };
    transformer.nodes().forEach(node => {
      initialPositionsRef.current[node.id()] = {x: node.x(), y: node.y()};
    });
  };

  const handleTransformerDragMove = () => {
    const transformer = transformerRef.current;

    if (transformer.nodes().some(n => !n.attrs.elementFunctions)) return;
    const movementDelta = {
      x: transformer.x() - initialTransformerPositionRef.current.x,
      y: transformer.y() - initialTransformerPositionRef.current.y,
    };
    const nodesWithPos = transformer.nodes().map(node => {
      const initialPos = {
        x: initialPositionsRef.current[node.id()].x,
        y: initialPositionsRef.current[node.id()].y,
      };
      const pos = {x: initialPos.x + movementDelta.x, y: initialPos.y + movementDelta.y};
      const dragBoundPos = node.attrs.elementFunctions.dragBoundFunc(pos);
      const dragBoundOffset = {
        x: dragBoundPos.x - pos.x,
        y: dragBoundPos.y - pos.y
      };

      return {node, pos, dragBoundOffset};
    });
    const dragBoundOffset = {
      x: nodesWithPos.reduce((prev, current) => movementDelta.x < 0
        ? Math.max(prev, current.dragBoundOffset.x)
        : Math.min(prev, current.dragBoundOffset.x), 0),
      y: nodesWithPos.reduce((prev, current) => movementDelta.y < 0
        ? Math.max(prev, current.dragBoundOffset.y)
        : Math.min(prev, current.dragBoundOffset.y), 0)
    };

    nodesWithPos.forEach(nwp => {
      nwp.node.x(nwp.pos.x + dragBoundOffset.x);
      nwp.node.y(nwp.pos.y + dragBoundOffset.y);
    });

    if (!!mouseDownElementRef.current?.attrs.elementFunctions) {
      mouseDownElementRef.current.attrs.elementFunctions.handleDragMove(mouseDownElementRef.current);
    }
  };

  const handleTransformerDragEnd = () => {
    isTransformerDraggingRef.current = false;
    initialPositionsRef.current = {};
    initialTransformerPositionRef.current = {};
    syncTransformer()
  };

  return {
    transformerRef,
    layerRef,
    selectionRectRef,
    checkDeselect,
    onMouseDown,
    onMouseMove,
    onMouseUp,
    onClickTap,
    onDblClickTap,
    onDragStart,
    handleTransformerDragStart,
    handleTransformerDragMove,
    handleTransformerDragEnd,
  };
}
