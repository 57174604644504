import React from 'react'

import {
  Step,
  Stepper as MuiStepper,
  Grid,
  StepButton,
  StepLabel,
  StepConnector,
  Typography,
} from '@mui/material';
import {useRouteMatch, useHistory} from "react-router-dom"

import {grayBackgroundColor} from "../../constants"
import {useDispatch, useSelector} from "react-redux"
import {authenticationActions} from "../../actions"
import {StepIcon} from "./StepIcon"
import {
  CONFIGURATION_STEPS,
  getStepByRoute,
  getStepDescription,
  getStepIndex,
  isStepEnabled
} from "./configurationSteps"

import {styled} from '@mui/material/styles';
import {stepConnectorClasses} from '@mui/material/StepConnector';


const Connector = styled(StepConnector)(({theme}) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 13,
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 2,
    border: 0,
    backgroundColor: '#eaeaf0',
    borderRadius: 1,
  },
}));

const StyledIcon = styled('div')(({theme, active, disabled, completed}) => ({
  boxShadow: '0 4px 10px 0 rgba(0,0,0,.25)',
  backgroundImage:
    'linear-gradient(136deg, rgba(120, 120, 120, 0.8) 0%, rgba(120, 120, 120, 0.9) 50%, rgba(120, 120, 120, 1) 100%)',
  zIndex: 1,
  color: '#fff',
  width: 30,
  height: 30,
  display: 'flex',
  borderRadius: '50%',
  justifyContent: 'center',
  alignItems: 'center',
  ...(active && {
    backgroundImage:
      'linear-gradient(136deg, rgba(239, 139, 45, 0.8) 0%, rgba(239, 139, 45, 0.9) 50%, rgba(239, 139, 45, 1) 100%)',
  }),
  ...(disabled && {
    backgroundImage:
      'linear-gradient(136deg, rgba(204, 204, 204, 0.8) 0%, rgba(204, 204, 204, 0.9) 50%, rgba(204, 204, 204, 1) 100%)',
  }),
  ...(completed && {
    backgroundImage:
      'linear-gradient(136deg, rgb(242,113,33) 0%, rgb(233,64,87) 50%, rgb(138,35,135) 100%)',
  }),
}));

function CircleStepIcon(props) {
  const {active, disabled, completed, icon} = props;
  const icons = CONFIGURATION_STEPS.map((current) => current.id);

  return (
    <StyledIcon active={active} disabled={disabled} completed={completed}>
      {icons[String(icon - 1)]}
    </StyledIcon>
  );
}

export const Stepper = () => {
  const history = useHistory()
  const dispatch = useDispatch()
  const plan = useSelector(state => state.plan)
  const match = useRouteMatch()
  const step = getStepByRoute(match.url)
  const stepIndex = !!step ? getStepIndex(step.id) : 0

  return (
    <MuiStepper
      alternativeLabel
      nonLinear
      elevation={1}
      activeStep={stepIndex}
      connector={<Connector/>}
      style={{padding: '12px 0px 2px 0px'}}
      sx={{
        padding: '12px, 0px, 2px, 0px',
        background: grayBackgroundColor,
      }}
    >
      {CONFIGURATION_STEPS.map((current, index) => {
        const stepProps = {}
        const disabled = !isStepEnabled(plan, current.id)

        return (
          <Step key={current.description} {...stepProps} disabled={disabled}>
            <StepButton
              onClick={() => {
                history.push("/step/" + (current.id))
                dispatch(authenticationActions.extendLoginIfRequired())
              }}>
              <StepLabel
                StepIconComponent={
                  (props) => (<CircleStepIcon {...props} disabled={disabled}/>)
                }
              >
                <Grid container direction="column" sx={{marginTop: '-8px'}}>
                  <Grid item>
                    <Typography
                      component="p"
                      variant="body2"
                      sx={{color: disabled ? 'gray' : 'black'}}
                    >
                      {getStepDescription(plan, current.id)}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <StepIcon scale={4} step={index} disabled={disabled} isSelected={index === stepIndex}/>
                  </Grid>
                </Grid>
              </StepLabel>
            </StepButton>
          </Step>
        )
      })}
    </MuiStepper>
  )
}
