import React from "react"

import {useTheme, Paper} from "@mui/material";
import Grid from "@mui/material/Grid"

import {orangeBackgroundColor} from "../../../constants"
import {DeliveryTypeSection} from "./DeliveryTypeSection"
import {QuantitySection} from "./QuantitySection"
import {DeadlineSection} from "./DeadlineSection"
import {ConstructionSiteSection} from "./ConstructionSiteSection"

export const OrderStep = () => {
  const theme = useTheme()

  return (
    <Paper square={true}
           sx={{
             background: orangeBackgroundColor,
             padding: theme.spacing(6)
           }}>
      <Grid container spacing={6}>
        <Grid item xs={6}>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <QuantitySection/>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={6}>
          <Grid container spacing={6}>
            <Grid item xs={12}>
              <DeliveryTypeSection/>
            </Grid>
            <Grid item xs={12}>
              <DeadlineSection/>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <Grid>
        <ConstructionSiteSection/>
      </Grid>
    </Paper>
  )
}
