export const getVaultSize = (plan, ignoreVaultAbsence = false) => {
  if (!ignoreVaultAbsence && !plan.vault) {
    return {width: 0, length: 0, height: 0, x: 0, y: 0}
  }
  return {
    width: plan.width + 2 * plan.wallWidth,
    length: plan.length + 2 * plan.wallWidth,
    height: !!plan.vault ? plan.vault.height : 0,
    x: -plan.wallWidth,
    y: -plan.wallWidth
  }
}

const getVaultLeft = plan => getVaultSize(plan, true).x
const getVaultLength = plan => getVaultSize(plan, true).length
const getVaultRight = plan => getVaultLeft(plan, true) + getVaultLength(plan)
const getVaultFront = plan => getVaultSize(plan, true).y
const getVaultWidth = plan => getVaultSize(plan, true).width
const getVaultBack = plan => getVaultFront(plan, true) + getVaultWidth(plan)
const getClearMeasureLeft = () => 0
const getClearMeasureRight = plan => getClearMeasureLeft() + plan.length
const getClearMeasureFront = () => 0
const getClearMeasureBack = plan => getClearMeasureFront() + plan.width

export const vaultFunctions = {
  getSize: plan => getVaultSize(plan, true),
  getLeft: getVaultLeft,
  getLength: getVaultLength,
  getRight: getVaultRight,
  getFront: getVaultFront,
  getWidth: getVaultWidth,
  getBack: getVaultBack,
  getClearMeasureLeft,
  getClearMeasureRight,
  getClearMeasureFront,
  getClearMeasureBack
}

export const getStandardVaultPresetDimensions = lidType => {
  switch (lidType) {
    case 'A15':
    case 'B125':
    case 'subfloor':
      return [
        {length: 100, width: 100},
        {length: 150, width: 100},
        {length: 200, width: 100},
        {length: 250, width: 100},
        {length: 300, width: 100}
      ]

    case 'D400':
    case 'E600':
      return [
        {length: 100, width: 100},
        {length: 150, width: 100},
        {length: 200, width: 100},
        {length: 260, width: 100},
        {length: 310, width: 100}
      ]

    default:
      return []
  }
}
