import {snapCircleToGrid} from "../Plan/snap";
import uuid from "react-uuid";

const getSnappedPosition = (configAreaWidth, configAreaHeight, item, minDistanceToBorder = 0) => {
  return {
    ...item,
    ...snapCircleToGrid(item, item.diameter / 2 + minDistanceToBorder, configAreaWidth, configAreaHeight)
  }
}

const getMinRadius = () => 2.5
const getMaxRadius = (configAreaWidth, configAreaHeight, item, minDistanceToBorder = 0) => Math.min(...[
  item.x,
  configAreaWidth - item.x,
  item.y,
  configAreaHeight - item.y
]) - minDistanceToBorder

const getDiameterWithinMinAndMax = (configAreaWidth, configAreaHeight, item, minDistanceToBorder = 0) => {
  const getMinDiameter = () => getMinRadius() * 2
  const getMaxDiameter = () => getMaxRadius(configAreaWidth, configAreaHeight, item, minDistanceToBorder) * 2

  return Math.max(Math.min(item.diameter, getMaxDiameter()), getMinDiameter())
}

const getSnappedDiameter = (configAreaWidth, configAreaHeight, item, minDistanceToBorder) => {
  const stepSize = () => 2.5
  const diameter = getDiameterWithinMinAndMax(configAreaWidth, configAreaHeight, item, minDistanceToBorder)

  return stepSize() * Math.round(diameter / stepSize())
}

export const previewAddCircularElement = (configAreaWidth, configAreaHeight, item, minDistanceToBorder = 0) => {
  return getSnappedPosition(configAreaWidth, configAreaHeight, item, minDistanceToBorder)
}

export const addCircularElement = (
  dispatchActionFunction,
  configAreaWidth,
  configAreaHeight,
  item,
  minDistanceToBorder = 0
) => {
  const snapped = getSnappedPosition(configAreaWidth, configAreaHeight, item, minDistanceToBorder)

  if (Number.isFinite(snapped.x) && Number.isFinite(snapped.y)) {
    const id = uuid()
    const it = {id, ...item, x: snapped.x, y: snapped.y}
    dispatchActionFunction(it)
  }
  return snapped
}

export const previewMoveCircularElement = (configAreaWidth, configAreaHeight, item, minDistanceToBorder = 0) => {
  return getSnappedPosition(configAreaWidth, configAreaHeight, item, minDistanceToBorder)
}

export const moveCircularElement = (
  dispatchActionFunction,
  configAreaWidth,
  configAreaHeight,
  item,
  minDistanceToBorder = 0
) => {
  const snapped = getSnappedPosition(configAreaWidth, configAreaHeight, item, minDistanceToBorder)

  dispatchActionFunction(item.id, snapped.x, snapped.y)
  return snapped
}

export const previewResizeCircularElement = (configAreaWidth, configAreaHeight, item, minDistanceToBorder = 0) => {
  return {
    ...item,
    diameter: getSnappedDiameter(configAreaWidth, configAreaHeight, item, minDistanceToBorder)
  }
}

export const resizeCircularElement = (
  dispatchActionFunction,
  configAreaWidth,
  configAreaHeight,
  item,
  minDistanceToBorder = 0
) => {
  const snapped = previewResizeCircularElement(configAreaWidth, configAreaHeight, item, minDistanceToBorder)

  dispatchActionFunction(item.id, snapped.diameter)
  return snapped
}

export const circularElementResizeBoundFunc = (
  pos,
  configAreaWidth,
  configAreaHeight,
  item,
  minDistanceToBorder = 0
) => {
  const minX = () => getMinRadius() + item.x
  const maxX = () => getMaxRadius(configAreaWidth, configAreaHeight, item, minDistanceToBorder) + item.x
  const x = () => Math.max(Math.min(pos.x, maxX()), minX())

  return {...pos, x: x()}
}

