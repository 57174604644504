import React, {forwardRef, useCallback, useEffect, useImperativeHandle} from "react"
import {Layer, Rect, Stage, Transformer} from "react-konva"

import {selectionActions} from "../../../../actions";
import {useDispatch, useSelector} from "react-redux"
import {createPart} from "./part"
import {createBottomAlignedGrid} from "../grid"
import {createMeasurements} from "../../configurationPlanMeasurement"
import {createToolbar} from "./vaultPlanToolbar"
import {createCircularElementCrossHairLayer} from "../../Element/circularElementCrossHairs"
import {createRectangularElementCrossHairLayer} from "../../Element/rectangularElementCrossHairs"
import {createLinearElementCrossHairLayer} from "../../Element/linearElementCrossHairs"
import {AutoResizeDiv} from "../../AutoResizeDiv"
import {renderGroupElements as renderSleeveGroupElements} from "../../Element/sleeveRenderFactory"
import {renderGroupElements as renderDrainageGroupElements} from "../../Element/drainageRenderFactory"
import {renderGroupElements as renderGroundingGroupElements} from "../../Element/groundingRenderFactory"
import {renderGroupElements as renderBlockOutGroupElements} from "../../Element/blockOutRenderFactory"
import {renderGroupElements as renderCRailGroupElements} from "../../Element/cRailRenderFactory"
import {renderCircularPlanElement} from "../../Element/circularElementFunctions"
import {renderRectangularPlanElement} from "../../Element/rectangularElementFunctions";
import {renderLinearPlanElement} from "../../Element/linearElementFunctions";
import {createVaultDomainFunctions} from "../../../domain/vaultDomainFunctions";
import {createElevationPlanLayout} from "../planLayout";
import {useCopyPaste} from "../CopyPasteContext";
import uuid from "react-uuid";
import {useConfigurationPlanInteractions} from "../configurationPlanInteractions";

const Plan = forwardRef((props, ref) => {
  const {
    wallIndex,
    isToolbarVisible,
    clientSize
  } = props
  const dispatch = useDispatch()
  const selectedIds = useSelector(state => state.selection)
  const plan = useSelector(state => state.plan)
  const planWidth = useSelector(state => state.plan.width)
  const planLength = useSelector(state => state.plan.length)
  const wallWidth = useSelector(state => state.plan.wallWidth)
  const vault = useSelector(state => state.plan.vault)
  const planHeight = vault ? vault.height : 0
  const wall = vault && wallIndex >= 0 ? vault.walls[wallIndex] : null
  const sleeves = wall ? wall.sleeves : []
  const blockOuts = wall ? wall.blockOuts : []
  const groundings = wall ? wall.groundings : []
  const drainages = wall ? wall.drainages : []
  const cRails = wall ? wall.cRails : []
  const layout = createElevationPlanLayout(clientSize, plan, wallIndex, isToolbarVisible)
  const width = wallIndex === 0 || wallIndex === 1 ? planLength : planWidth
  const domainFunctions = createVaultDomainFunctions(dispatch, width, planHeight, wallIndex)
  const {copy, paste} = useCopyPaste(domainFunctions)

  const clearSelection = useCallback(() => {
    dispatch(selectionActions.clearSelected());
  }, [dispatch]);

  useEffect(() => {
    clearSelection();
  }, [clearSelection, wallIndex]);

  const handleCopy = () => {
    const toCopy = {
      context: 'vault',
      contextSide: wallIndex,
      sleeves: sleeves.filter(sleeve => selectedIds.includes(sleeve.id)),
      blockOuts: blockOuts.filter(blockOut => selectedIds.includes(blockOut.id)),
      groundings: groundings.filter(grounding => selectedIds.includes(grounding.id)),
      drainages: drainages.filter(drainage => selectedIds.includes(drainage.id)),
      cRails: cRails.filter(cRail => selectedIds.includes(cRail.id)),
    }
    copy(toCopy);
  };

  const handlePaste = () => {
    const pastedObject = paste();

    if (!pastedObject || pastedObject.context !== 'vault') {
      return;
    }
    const translateY = (elements, y) =>
      Array.isArray(elements)
        ? elements.map(element => {
          return {...element, y: element.y + y}
        })
        : null
    const translateYBy = pastedObject.contextSide !== wallIndex ? 0 : -20;
    const translated = ({
      sleeves: translateY(pastedObject.sleeves, translateYBy),
      blockOuts: translateY(pastedObject.blockOuts, translateYBy),
      drainages: translateY(pastedObject.drainages, translateYBy),
      groundings: translateY(pastedObject.groundings, translateYBy),
      cRails: translateY(pastedObject.cRails, translateYBy),
    })
    const pasteElements = (elements, addFunc) =>
      Array.isArray(elements)
        ? elements.map(element => {
          const id = uuid();

          addFunc({...element, id: id});
          return id;
        })
        : []

    dispatch(selectionActions.setSelected([
      ...pasteElements(translated.sleeves, domainFunctions.sleeveFunctions.add),
      ...pasteElements(translated.blockOuts, domainFunctions.blockOutFunctions.add),
      ...pasteElements(translated.drainages, domainFunctions.drainageFunctions.add),
      ...pasteElements(translated.groundings, domainFunctions.groundingFunctions.add),
      ...pasteElements(translated.cRails, domainFunctions.cRailFunctions.add),
    ]));
    copy({...pastedObject, ...translated, contextSide: wallIndex});
  };

  const handleDelete = () => {
    const del = (elements, functions) =>
      elements.filter(el => selectedIds.includes(el.id)).forEach(el => functions.remove(el.id))

    del(sleeves, domainFunctions.sleeveFunctions);
    del(blockOuts, domainFunctions.blockOutFunctions);
    del(drainages, domainFunctions.drainageFunctions);
    del(groundings, domainFunctions.groundingFunctions);
    del(cRails, domainFunctions.cRailFunctions);
    dispatch(selectionActions.clearSelected)
  };

  useImperativeHandle(ref, () => ({
    delete: () => handleDelete(),
    copy: () => handleCopy(),
    paste: () => handlePaste(),
  }));

  const {
    transformerRef,
    layerRef,
    selectionRectRef,
    checkDeselect,
    onMouseDown,
    onMouseMove,
    onMouseUp,
    onClickTap,
    onDblClickTap,
    onDragStart,
    handleTransformerDragStart,
    handleTransformerDragMove,
    handleTransformerDragEnd,
  } = useConfigurationPlanInteractions(ref, props, layout, handleDelete);

  const createSleeve = sleeve => {
    return renderCircularPlanElement(
      domainFunctions.sleeveFunctions,
      layout.configArea,
      sleeve,
      renderSleeveGroupElements
    )
  }

  return (
    <div>
      {!isNaN(layout.scale) && vault &&
        <Stage
          name={"stage"}
          width={clientSize.width}
          height={layout.overall.screenHeight}
          onMouseDown={onMouseDown}
          onMouseUp={onMouseUp}
          onMouseMove={onMouseMove}
          onTouchStart={checkDeselect}
          onClick={onClickTap}
          onTap={onClickTap}
          onDblClick={onDblClickTap}
          onDblTap={onDblClickTap}
          onDragStart={onDragStart}
        >
          <Layer ref={layerRef}>
            {createPart(layout.part, layout.configArea, wallWidth)}
            {wallIndex >= 0 && createBottomAlignedGrid(layout.configArea, layout.configAreaRealWorldTransform)}
            {
              createMeasurements(
                [...sleeves, ...groundings, ...drainages],
                cRails,
                blockOuts,
                layout.configArea.realWidth,
                wallWidth,
                layout.horizontalMeasurements,
                layout.verticalMeasurements)
            }
            {
              sleeves.map(sleeve => createSleeve(sleeve))
            }
            {
              drainages.map(drainage => renderCircularPlanElement(
                domainFunctions.drainageFunctions,
                layout.configArea,
                drainage,
                renderDrainageGroupElements
              ))
            }
            {
              blockOuts.map(blockOut => renderRectangularPlanElement(
                domainFunctions.blockOutFunctions,
                layout.configArea,
                blockOut,
                renderBlockOutGroupElements
              ))
            }
            {
              cRails.map(cRail => renderLinearPlanElement(
                domainFunctions.cRailFunctions,
                layout.configArea,
                cRail,
                renderCRailGroupElements
              ))
            }
            {
              groundings.map(grounding => renderCircularPlanElement(
                domainFunctions.groundingFunctions,
                layout.configArea,
                grounding,
                renderGroundingGroupElements
              ))
            }
            {
              isToolbarVisible
                ? createToolbar(
                  layout.toolbar,
                  layout.configArea,
                  wallIndex,
                  domainFunctions
                )
                : null
            }
            <Transformer
              ref={transformerRef}
              resizeEnabled={false}
              rotateEnabled={false}
              draggable={true}
              onDragStart={handleTransformerDragStart}
              onDragMove={handleTransformerDragMove}
              onDragEnd={handleTransformerDragEnd}
            />
            <Rect fill="rgba(0,0,255,0.5)" ref={selectionRectRef}/>
          </Layer>
          {createRectangularElementCrossHairLayer()}
          {createLinearElementCrossHairLayer()}
          {createCircularElementCrossHairLayer()}
        </Stage>
      }
      {/*
      <button onClick={() => handleCopy()}>Copy</button>
      <button onClick={() => handlePaste()}>Paste</button>
      <button onClick={() => handleDelete()}>Delete</button>
      */}
    </div>
  )
})

export const VaultPlan = props => {
  const {childRef} = props
  return (
    <AutoResizeDiv>
      <Plan ref={childRef} {...props}/>
    </AutoResizeDiv>
  )
}
