import React from "react";

import Konva from "konva";
import {Group} from "react-konva";

import {toRealLine, toScreenLine} from "../realWorldTransform";
import {setOpacity} from "./elementFunctions";
import {hideCrossHairs, updateCrossHairs} from "./linearElementCrossHairs";
import {PLAN_ITEM_OPACITY} from "../../../constants";
import {renderResizeHandles} from "./linearElementsResizeHandles";

export const renderLinearPlanElement = (
  domainFunctions,
  configAreaLayout,
  element,
  renderGroupElementsFunction
) => {
  const screenZero = {x: configAreaLayout.screenX, y: configAreaLayout.screenY}
  const screenLine = toScreenLine(element, configAreaLayout.transform, screenZero)
  const handleDragStart = e => {
    setOpacity(e.target, "dragging")
  }
  const handleDragMove = shapeGroup => {
    const group = shapeGroup
    const line = {...screenLine, x: group.x(), y: group.y()}
    const realLine = toRealLine(line, configAreaLayout.transform, screenZero)
    const movedElement = domainFunctions.previewMove({...element, ...realLine})
    const newScreenLine = toScreenLine(movedElement, configAreaLayout.transform, screenZero)
    const stage = group.getStage()

    updateCrossHairs(configAreaLayout, stage, newScreenLine, movedElement)
  }
  const handleDragEnd = e => {
    const group = e.target
    const line = {...screenLine, x: group.x(), y: group.y()}
    const realLine = toRealLine(line, configAreaLayout.transform, screenZero)
    const movedElement = domainFunctions.move({...element, ...realLine})
    const newScreenLine = toScreenLine(movedElement, configAreaLayout.transform, screenZero)

    hideCrossHairs(group.getStage())
    setOpacity(group)
    e.target.to({
      duration: 0.05,
      easing: Konva.Easings.StrongEaseOut,
      x: newScreenLine.x,
      y: newScreenLine.y
    })
  }
  const handleResizing = (handle, resizeLine) => {
    const stage = handle.getStage()
    const cRailGroup = handle.parent
    const rectShape = cRailGroup.find('Rect')[0]
    const direction = handle.name()
    const lengthText = cRailGroup.find('.length')[0]
    const realLine = toRealLine(
      {
        x: screenLine.x + resizeLine.x,
        y: screenLine.y + resizeLine.y,
        length: resizeLine.length,
        orientation: screenLine.orientation
      },
      configAreaLayout.transform,
      screenZero
    )
    const resizedLine = domainFunctions.previewResize({...element, ...realLine}, direction)
    const newScreenLine = toScreenLine(resizedLine, configAreaLayout.transform, screenZero)

    switch (resizeLine.orientation) {
      case 'horizontal':
        rectShape.x(resizeLine.x)
        rectShape.width(resizeLine.length)
        break

      case 'vertical':
        rectShape.y(resizeLine.y - resizeLine.length)
        rectShape.height(resizeLine.length)
        break

      default:
    }

    switch (resizeLine.orientation) {
      case 'horizontal':
        lengthText.x(resizeLine.x + resizeLine.length / 2 - 50)
        break

      case 'vertical':
        lengthText.y(resizeLine.y - resizeLine.length / 2 + 50)
        break

      default:
    }
    stage.findOne('Transformer').hide()
    lengthText.text(resizedLine.length)
    setOpacity(cRailGroup, "resizing")
    updateCrossHairs(configAreaLayout, stage, newScreenLine, resizedLine)
  }

  const handleResized = (handle, resizeLine) => {
    const stage = handle.getStage()
    const cRailGroup = handle.parent
    const rectShape = cRailGroup.find('Rect')[0]
    const direction = handle.name()
    const realLine = toRealLine(
      {
        x: screenLine.x + resizeLine.x,
        y: screenLine.y + resizeLine.y,
        length: resizeLine.length,
        orientation: screenLine.orientation
      },
      configAreaLayout.transform,
      screenZero
    )
    const resizedLine = domainFunctions.resize({...element, ...realLine}, direction)
    const newScreenLine = toScreenLine(resizedLine, configAreaLayout.transform, screenZero)

    switch (resizedLine.orientation) {
      case 'horizontal':
        rectShape.x(0)
        rectShape.width(newScreenLine.length)
        break

      case 'vertical':
        rectShape.y(0 - newScreenLine.length)
        rectShape.height(newScreenLine.length)
        break

      default:
    }
    switch (direction) {
      case 'left':
        handle.x(0)
        break

      case 'right':
        handle.x(newScreenLine.length)
        break

      case 'top':
        handle.y(-newScreenLine.length)
        break

      case 'bottom':
        handle.y(0)
        break

      default:
    }
    setOpacity(cRailGroup)
    hideCrossHairs(stage)
    stage.findOne('Transformer').show()
  }
  const dragBoundFunc = pos => {
    const halfCRailScreenWidth = 1.9 * configAreaLayout.scale
    const toBound = (minX, maxX, minY, maxY) => {
      return {
        x: Math.max(minX, Math.min(pos.x, maxX)),
        y: Math.max(minY, Math.min(pos.y, maxY))
      }
    }

    switch (element.orientation) {
      case 'horizontal': {
        return toBound(
          configAreaLayout.screenX,
          configAreaLayout.screenX + configAreaLayout.screenWidth - screenLine.length,
          configAreaLayout.screenY + halfCRailScreenWidth,
          configAreaLayout.screenY + configAreaLayout.screenHeight - halfCRailScreenWidth)
      }

      case 'vertical':
        return toBound(
          configAreaLayout.screenX + halfCRailScreenWidth,
          configAreaLayout.screenX + configAreaLayout.screenWidth - halfCRailScreenWidth,
          configAreaLayout.screenY + screenLine.length,
          configAreaLayout.screenY + configAreaLayout.screenHeight
        )

      default:
        return pos
    }
  }

  return (
    <Group
      name="plan_element"
      key={"element_" + element.id}
      id={element.id}
      x={screenLine.x}
      y={screenLine.y}
      opacity={PLAN_ITEM_OPACITY}
      draggable={!!domainFunctions && !!domainFunctions.previewMove && !!domainFunctions.move}
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
      element={element}
      domainFunctions={domainFunctions}
      elementFunctions={{dragBoundFunc, handleDragMove}}
    >
      {renderGroupElementsFunction(screenLine, element)}
      {
        !!domainFunctions && !!domainFunctions.previewResize && !!domainFunctions.resize &&
        renderResizeHandles(
          configAreaLayout,
          element.orientation,
          screenLine,
          screenLine.length,
          handleResizing,
          handleResized
        )
      }
    </Group>
  )
}
