import {planApiActionTypes} from "../constants"
import {planService} from "../services"
import {planActions, projectApiActions} from "."
import {reset as resetProject} from "./../reducers/projectReducer"
import {addIntermediateCeilingSize, addLidSize, prepareImages} from "./planApiHelpers";

export const createPlan = (plan, planImages) => {
  const request = () => {
    return {type: planApiActionTypes.createPlanRequest}
  }
  const success = () => {
    return {type: planApiActionTypes.createPlanSuccess}
  }
  const failure = error => {
    return {type: planApiActionTypes.createPlanFailure, error}
  }

  return dispatch => {
    dispatch(request())
    setTimeout(() => {
        planService.createPlan({
          plan: addLidSize(addIntermediateCeilingSize(plan)),
          planImages: prepareImages(plan, planImages)
        })
          .then(
            () => {
              dispatch(success())
              dispatch(resetProject())
              dispatch(planActions.reset())
            },
            error => {
              dispatch(failure(error))
            }
          )
      },
      300)
  }
}

const readPlan = (id, history) => {
  const request = () => {
    return {type: planApiActionTypes.readPlanRequest}
  }
  const success = result => {
    return {type: planApiActionTypes.readPlanSuccess, data: result}
  }
  const failure = error => {
    return {type: planApiActionTypes.readPlanFailure, error}
  }

  return dispatch => {
    dispatch(request())
    planService.readPlan(id)
      .then(
        result => {
          dispatch(success(result))
          history.push('/step/1')
        },
        error => dispatch(failure(error))
      )
  }
}

const deletePlan = id => {
  const request = () => {
    return {type: planApiActionTypes.deletePlanRequest}
  }
  const success = id => {
    return {type: planApiActionTypes.deletePlanSuccess, data: id}
  }
  const failure = error => {
    return {type: planApiActionTypes.deletePlanFailure, error}
  }

  return dispatch => {
    dispatch(request())
    planService.deletePlan(id)
      .then(
        () => dispatch(success(id)),
        error => dispatch(failure(error))
      )
  }
}

export const resetPlans = () => {
  return {
    type: planApiActionTypes.resetPlans
  }
}

const readPlans = (limit = 15, searchTerm = '') => {
  const request = () => {
    return {type: planApiActionTypes.readPlansRequest}
  }
  const success = result => {
    return {type: planApiActionTypes.readPlansSuccess, data: result}
  }
  const failure = error => {
    return {type: planApiActionTypes.readPlansFailure, error}
  }

  return dispatch => {
    dispatch(request())
    planService.readPlans(limit, searchTerm)
      .then(
        result => dispatch(success(result)),
        error => dispatch(failure(error))
      )
  }
}

const readPlanDetails = (id) => {
  const request = () => {
    return {type: planApiActionTypes.readPlanDetailsRequest}
  }
  const success = result => {
    return {type: planApiActionTypes.readPlanDetailsSuccess, data: result}
  }
  const failure = error => {
    return {type: planApiActionTypes.readPlanDetailsFailure, error}
  }

  return dispatch => {
    dispatch(request())
    planService.readPlanDetails(id)
      .then(
        result => dispatch(success(result)),
        error => dispatch(failure(error))
      )
  }
}

const setOwner = (id, owner) => {
  const request = () => {
    return {type: planApiActionTypes.setOwnerRequest}
  }
  const success = data => {
    return {type: planApiActionTypes.setOwnerSuccess, data}
  }
  const failure = error => {
    return {type: planApiActionTypes.setOwnerFailure, error}
  }

  return (dispatch, getState) => {
    dispatch(request())
    planService.changeOwner(id, owner)
      .then(
        data => {
          dispatch(success(data))
          if (getState().projectDetails.plan?.id === id) {
            dispatch(projectApiActions.readProjectDetails(getState().projectDetails.project.id))
          }
          if (getState().planDetails.plan?.id === id) {
            dispatch(planApiActions.readPlanDetails(id))
          }
        },
        error => {
          dispatch(failure(error))
        }
      )
  }
}

export const planApiActions = {
  createPlan,
  readPlan,
  deletePlan,
  resetPlans,
  readPlans,
  readPlanDetails,
  setOwner
}
