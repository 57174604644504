import { Button, ButtonGroup, Grid, Typography } from "@mui/material";
import React from "react"
import {useDispatch, useSelector} from "react-redux"
import {setDeliveryType} from "../../../reducers/deliverySlice";
import {grayButtonStyle, orangeButtonStyle} from "../../../constants"

const TitleGridItem = () => {
  return (
    <Grid item xs={12}>
      <Typography component="h1" variant="h4">
        Lieferart
      </Typography>
      < hr/>
    </Grid>
  )
}

const DeliveryTypeGridItem = () => {
  const dispatch = useDispatch()
  const deliveryType = useSelector(state => state.project.delivery.deliveryType)

  return (
    <Grid item xs={12}>
      <ButtonGroup
        fullWidth
        variant="contained"
      >
        <Button
          sx={deliveryType === "delivery" ? orangeButtonStyle : grayButtonStyle}
          color={deliveryType === "delivery" ? "primary" : "inherit"}
          onClick={() => {
            dispatch(setDeliveryType("delivery"))
          }}
        >
          Lieferung
        </Button>
        <Button
          sx={deliveryType === "pickupFactory" ? orangeButtonStyle : grayButtonStyle}
          color={deliveryType === "pickupFactory" ? "primary" : "inherit"}
          onClick={() => {
            dispatch(setDeliveryType("pickupFactory"))
          }}
        >
          Abholung ab Werk
        </Button>
      </ButtonGroup>
    </Grid>
  )
}

export const DeliveryTypeSection = () => {
  return (
    <Grid container spacing={4}>
      <TitleGridItem/>
      <DeliveryTypeGridItem/>
    </Grid>
  )
}
