import React from "react";

import Konva from "konva";
import {Group, Text} from "react-konva";

import {
  toRealPosition,
  toRealRect,
  toRealRectPosition, toScreenPosition,
  toScreenRect,
  toScreenRectPosition
} from "../realWorldTransform";
import {setOpacity} from "./elementFunctions";
import {hideCrossHairs, updateCrossHairs} from "./rectangularElementCrossHairs";
import {FONT_SIZE, PLAN_ITEM_OPACITY} from "../../../constants";
import {renderResizeHandles} from "./rectangularElementsResizeHandles";

const widthTextSpacing = 9
const heightTextSpacing = 11
const positionTextSpacing = 2

export const renderMeasurements = (screenRect, realRect, renderWidthAndHeight) => {
  return (
    <React.Fragment>
      {
        renderWidthAndHeight &&
        realRect !== undefined && realRect.width !== undefined && realRect.height !== undefined &&
        (
          <React.Fragment>
            <Text
              name="width"
              x={screenRect.width / 2 - 100}
              y={screenRect.height - FONT_SIZE - widthTextSpacing}
              width={200}
              height={FONT_SIZE}
              fontSize={FONT_SIZE}
              align="center"
              verticalAlign="bottom"
              text={realRect.width}
              fill="black"
            />
            <Text
              name="height"
              x={screenRect.width - 200 - heightTextSpacing}
              y={screenRect.height / 2 - FONT_SIZE / 2}
              width={200}
              height={FONT_SIZE}
              fontSize={FONT_SIZE}
              align="right"
              verticalAlign="middle"
              text={realRect.height}
              fill="black"
            />
          </React.Fragment>
        )
      }
      {
        realRect !== undefined && realRect.x !== undefined && realRect.y !== undefined &&
        <Text
          name="position"
          x={-75 - positionTextSpacing}
          y={screenRect.height + positionTextSpacing}
          width={75}
          height={FONT_SIZE}
          fontSize={FONT_SIZE}
          align="right"
          verticalAlign="top"
          text={realRect.x + "/" + realRect.y}
          fill="black"
        />
      }
    </React.Fragment>
  )
}

export const renderRectangularPlanElement = (
  domainFunctions,
  configAreaLayout,
  element,
  renderGroupElementsFunction,
  resizeDirections = ["left", "right", "top", "bottom"],
  renderWidthAndHeight = true
) => {
  const screenZero = {x: configAreaLayout.screenX, y: configAreaLayout.screenY}
  const screenRect = toScreenRect(element, configAreaLayout.transform, screenZero)
  const handleDragStart = e => {
    setOpacity(e.target, "dragging")
  }
  const handleDragMove = shapeGroup => {
    const group = shapeGroup
    const rect = {...screenRect, x: group.x(), y: group.y()}
    const realRect = toRealRect(rect, configAreaLayout.transform, screenZero)
    const movedElement = domainFunctions.previewMove({...element, ...realRect})
    const newScreenRect = toScreenRect(movedElement, configAreaLayout.transform, screenZero)
    const stage = group.getStage()

    updateCrossHairs(configAreaLayout, stage, newScreenRect, movedElement)
  }
  const handleDragEnd = e => {
    const group = e.target
    const rect = {...screenRect, x: group.x(), y: group.y()}
    const realRect = toRealRect(rect, configAreaLayout.transform, screenZero)
    const movedElement = domainFunctions.move({...element, ...realRect})
    const newScreenRect = toScreenRect(movedElement, configAreaLayout.transform, screenZero)

    hideCrossHairs(group.getStage())
    setOpacity(group)
    e.target.to({
      duration: 0.05,
      easing: Konva.Easings.StrongEaseOut,
      x: newScreenRect.x,
      y: newScreenRect.y
    })
  }
  const resize = (group, screenRect, realRect) => {
    const rect = group.find('.background')[0]

    rect.x(screenRect.x)
    rect.y(screenRect.y)
    rect.width(screenRect.width)
    rect.height(screenRect.height)
    const widthText = group.find('.width')[0]

    widthText?.x(screenRect.x + screenRect.width / 2 - 100)
    widthText?.y(screenRect.y + screenRect.height - FONT_SIZE - widthTextSpacing)
    widthText?.text(realRect.width)
    const heightText = group.find('.height')[0]

    heightText?.x(screenRect.x + screenRect.width - 200 - heightTextSpacing)
    heightText?.y(screenRect.y + screenRect.height / 2 - FONT_SIZE / 2)
    heightText?.text(realRect.height)
  }
  const handleResizing = (handle, resizeRect) => {
    handle.getStage().findOne("Transformer").hide()
    setOpacity(handle.parent, "resizing")
    const realRect = toRealRect(
      {...resizeRect, x: screenRect.x + resizeRect.x, y: screenRect.y + resizeRect.y,},
      configAreaLayout.transform,
      screenZero
    )
    const resizedBlockOut = domainFunctions.previewResize({...element, ...realRect}, handle.name())
    const newScreenRect = toScreenRect(resizedBlockOut, configAreaLayout.transform, screenZero)

    resize(handle.parent, resizeRect, resizedBlockOut)
    updateCrossHairs(configAreaLayout, handle.getStage(), newScreenRect, resizedBlockOut)
  }
  const handleResized = (handle, resizedRect) => {
    setOpacity(handle.parent)
    const realRect = toRealRect(
      {...resizedRect, x: screenRect.x + resizedRect.x, y: screenRect.y + resizedRect.y},
      configAreaLayout.transform,
      screenZero
    )
    const resizedBlockOut = domainFunctions.resize({...element, ...realRect}, handle.name())
    const newScreenRect = toScreenRect(resizedBlockOut, configAreaLayout.transform, screenZero)
    const stage = handle.getStage()

    resize(handle.parent, {...newScreenRect, x: 0, y: 0}, resizedBlockOut)
    hideCrossHairs(stage)
    stage.findOne("Transformer").show();
    return newScreenRect
  }

  const dragBoundFunc = pos => {
    if (!!domainFunctions.dragBoundFunc) {
      const getScreenZero = () => ({x: configAreaLayout.screenX, y: configAreaLayout.screenY})
      const getRealPos = () => toRealRectPosition(
        pos,
        element,
        configAreaLayout.transform,
        getScreenZero()
      )
      const getBoundPos = () => domainFunctions.dragBoundFunc(element, getRealPos())
      const getBoundScreenPos = () => toScreenRectPosition(
        getBoundPos(),
        element,
        configAreaLayout.transform,
        getScreenZero()
      )

      return getBoundScreenPos()
    }
    const minX = configAreaLayout.screenX
    const maxX = configAreaLayout.screenX + configAreaLayout.screenWidth - screenRect.width
    const x = Math.max(minX, Math.min(pos.x, maxX))
    const minY = configAreaLayout.screenY
    const maxY = configAreaLayout.screenY + configAreaLayout.screenHeight - screenRect.height
    const y = Math.max(minY, Math.min(pos.y, maxY))

    return {x, y}
  }
  const resizeBoundFunc = !!domainFunctions && !!domainFunctions.resizeBoundFunc
    ? pos => {
      const getScreenZero = () => ({x: configAreaLayout.screenX, y: configAreaLayout.screenY})
      const getRealPos = () => toRealPosition(
        pos,
        configAreaLayout.transform,
        getScreenZero()
      )
      const getBoundPos = () => domainFunctions.resizeBoundFunc(element, getRealPos())
      const getBoundScreenPos = () => toScreenPosition(
        getBoundPos(),
        configAreaLayout.transform,
        getScreenZero()
      )

      return getBoundScreenPos()
    }
    : null

  return (
    <Group
      name="plan_element"
      key={"element_" + element.id}
      id={element.id}
      x={screenRect.x}
      y={screenRect.y}
      opacity={PLAN_ITEM_OPACITY}
      draggable={!!domainFunctions && !!domainFunctions.previewMove && !!domainFunctions.move}
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
      element={element}
      domainFunctions={domainFunctions}
      elementFunctions={{dragBoundFunc, handleDragMove}}
    >
      {renderGroupElementsFunction(screenRect, element)}
      {renderMeasurements(screenRect, element, renderWidthAndHeight)}
      {
        !!domainFunctions && !!domainFunctions.previewResize && !!domainFunctions.resize &&
        renderResizeHandles(
          configAreaLayout,
          screenRect,
          handleResizing,
          handleResized,
          resizeDirections,
          resizeBoundFunc
        )
      }
    </Group>
  )
}
