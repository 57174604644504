import {createSlice} from "@reduxjs/toolkit";

const initialState = {
  description: '',
  deliveryType: 'delivery',
  desiredSchedule: '',
  street: '',
  zip: '',
  city: '',
  number: '', // optional
  person: '', // optional
  phone: '', // optional
  commission: '', // optional
  usersOrderNumber: '', // optional
  remarks: '' // optional
}

export const deliverySlice = createSlice({
  name: "delivery",
  initialState,
  reducers: {
    setDescription: (state, action) => ({...state, description: action.payload}),
    setDeliveryType: (state, action) => ({...state, deliveryType: action.payload}),
    setDesiredSchedule: (state, action) => ({...state, desiredSchedule: action.payload}),
    setStreet: (state, action) => ({...state, street: action.payload}),
    setZip: (state, action) => ({...state, zip: action.payload}),
    setCity: (state, action) => ({...state, city: action.payload}),
    setCoordinates: (state, action) =>  {
      console.log(action.payload)
      return ({...state, latitude: action.payload.lat, longitude: action.payload.lng})
    },
    unsetCoordinates: (state) => ({...state, latitude: undefined, longitude: undefined}),
    setNumber: (state, action) => ({...state, number: action.payload}),
    setPerson: (state, action) => ({...state, person: action.payload}),
    setPhone: (state, action) => ({...state, phone: action.payload}),
    setCommission: (state, action) => ({...state, commission: action.payload}),
    setUsersOrderNumber: (state, action) => ({...state, usersOrderNumber: action.payload}),
    setRemarks: (state, action) => ({...state, remarks: action.payload})
  }
});

export const {
  setDescription,
  setDeliveryType,
  setDesiredSchedule,
  setStreet,
  setZip,
  setCity,
  setCoordinates,
  unsetCoordinates,
  setNumber,
  setPerson,
  setPhone,
  setCommission,
  setUsersOrderNumber,
  setRemarks,
} = deliverySlice.actions;

export default deliverySlice.reducer;
