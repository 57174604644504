import {useTheme, Button, Alert} from "@mui/material"
import Grid from "@mui/material/Grid"
import {useDispatch} from "react-redux"
import {useHistory} from "react-router-dom"

const alertStyles = theme => ({
  margin: theme.spacing(1)
})

const planAlertStyles = theme => ({
  margin: theme.spacing(1),
  color: "#0055d4",
  backgroundColor: "transparent",
  "& .MuiAlert-icon": {
    color: "#0055d4"
  }
})

const AlertItem = props => {
  const {error, isPlanAlert} = props
  const theme = useTheme()
  const dispatch = useDispatch()
  const history = useHistory()

  const hasButton = error.resolveAction || error.resolveLocation
  const buttonText = error.resolveAction
    ? error.resolveActionText
    : error.resolveLocation
      ? error.resolveLocationText
      : ""
  const buttonAction = error.resolveAction
    ? () => dispatch(error.resolveAction())
    : error.resolveLocation
      ? () => history.push(error.resolveLocation)
      : null

  return (
    <Alert
      sx={{
        ...(isPlanAlert ? planAlertStyles(theme) : alertStyles(theme)),
        whiteSpace: "pre-line"
      }}
      severity={error.severity ? error.severity : "error"}
      action={
        hasButton && (
          <Button color="inherit" size="small" onClick={buttonAction}>
            {" "}
            {buttonText}{" "}
          </Button>
        )
      }
      backgroundcolor="transparent"
    >
      {error.message}
    </Alert>
  )
}

export const Alerts = props => {
  const {errors, isPlanAlert} = props
  return (
    <Grid item xs={12} key={"upper_text"}>
      {errors.map((e, i) => (
        <AlertItem key={"error_" + i} error={e} isPlanAlert={isPlanAlert}/>
      ))}
    </Grid>
  )
}
