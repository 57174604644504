import {selectionActionTypes as actionTypes} from '../constants';

const setSelected = ids => {
  return {
    type: actionTypes.setSelected,
    data: ids
  };
};

const addSelected = id => {
  return {
    type: actionTypes.addSelected,
    data: id
  }
};

const removeSelected = id => {
  return {
    type: actionTypes.removeSelected,
    data: id
  }
};

const clearSelected = () => {
  return {
    type: actionTypes.clearSelected
  };
};

export const selectionActions = {
  setSelected,
  addSelected,
  removeSelected,
  clearSelected
}