import React from "react"

import Konva from "konva"
import {Group, Text} from "react-konva"

import {toRealCircle, toRealPosition, toScreenPosition} from "../realWorldTransform"
import {setOpacity} from "./elementFunctions"
import {hideCrossHairs, updateCrossHairs} from "./circularElementCrossHairs"
import {FONT_SIZE, PLAN_ITEM_OPACITY} from "../../../constants"
import {renderResizeHandles} from "./circularElementsResizeHandles";

export const renderCircularPlanElement = (
  domainFunctions,
  configAreaLayout,
  element,
  renderGroupElementsFunction,
  minDistanceToBorder = 0
) => {
  const screenZero = {x: configAreaLayout.screenX, y: configAreaLayout.screenY}
  const screenPosition = toScreenPosition(element, configAreaLayout.transform, screenZero)
  const screenCircle = {...screenPosition, radius: element.outerDiameter / 2 * configAreaLayout.scale}
  const handleDragStart = e => {
    setOpacity(e.target, "dragging")
  }
  const handleDragMove = shapeGroup => {
    const group = shapeGroup
    const screenPosition = {x: group.x(), y: group.y()}
    const realPosition = toRealPosition(screenPosition, configAreaLayout.transform, screenZero)
    const moved = domainFunctions.previewMove({...element, ...realPosition})
    const newScreenPosition = toScreenPosition(moved, configAreaLayout.transform, screenZero)
    const stage = group.getStage()

    updateCrossHairs(
      element,
      configAreaLayout,
      configAreaLayout.transform,
      stage,
      newScreenPosition.x,
      newScreenPosition.y
    )
  }
  const handleDragEnd = e => {
    const group = e.target
    const screenPosition = {x: group.x(), y: group.y()}
    const realPosition = toRealPosition(screenPosition, configAreaLayout.transform, screenZero)
    const moved = domainFunctions.move({...element, ...realPosition})
    const newScreenPosition = toScreenPosition(moved, configAreaLayout.transform, screenZero)

    hideCrossHairs(group.getStage())
    setOpacity(group)
    e.target.to({
      duration: 0.05,
      easing: Konva.Easings.StrongEaseOut,
      x: newScreenPosition.x,
      y: newScreenPosition.y
    })
  }
  const resize = (group, screenCircle, realCircle) => {
    const circles = group.find('.background')
    const circle = Array.isArray(circles) && circles.length > 0 ? circles[0] : null;
    const {radius} = screenCircle

    if (!circle) { return }
    circle.radius(radius)
    const text = group.find('.dimension')

    text.forEach(t => t.text(realCircle.diameter * 10))
  }

  const handleResizing = (handle, resizeCircle) => {
    handle.getStage().findOne("Transformer").hide()
    setOpacity(handle.parent, "resizing")
    const realCircle = toRealCircle(resizeCircle, configAreaLayout.transform, screenZero)
    const resizedBlockOut = domainFunctions.previewResize({...element, ...realCircle})

    resize(handle.parent, resizeCircle, resizedBlockOut)
  }

  const handleResized = (handle, resizeCircle) => {
    setOpacity(handle.parent)
    const realCircle = toRealCircle(resizeCircle, configAreaLayout.transform, screenZero)
    const resizedBlockOut = domainFunctions.resize({...element, ...realCircle})

    resize(handle.parent, resizeCircle, resizedBlockOut)
    handle.getStage().findOne("Transformer").show()
  }

  const dragBoundFunc = pos => {
    const minX = configAreaLayout.screenX + screenCircle.radius + minDistanceToBorder
    const maxX = configAreaLayout.screenX + configAreaLayout.screenWidth - screenCircle.radius - minDistanceToBorder
    const x = Math.max(minX, Math.min(pos.x, maxX))
    const minY = configAreaLayout.screenY + screenCircle.radius + minDistanceToBorder
    const maxY = configAreaLayout.screenY + configAreaLayout.screenHeight - screenCircle.radius - minDistanceToBorder
    const y = Math.max(minY, Math.min(pos.y, maxY))

    return {x, y}
  }

  const resizeBoundFunc = !!domainFunctions && !!domainFunctions.resizeBoundFunc
    ? pos => {
      const getScreenZero = () => ({x: configAreaLayout.screenX, y: configAreaLayout.screenY})
      const getRealPos = () => toRealPosition(
        pos,
        configAreaLayout.transform,
        getScreenZero()
      )
      const getBoundPos = () => domainFunctions.resizeBoundFunc(element, getRealPos())
      const getBoundScreenPos = () => toScreenPosition(
        getBoundPos(),
        configAreaLayout.transform,
        getScreenZero()
      )

      return getBoundScreenPos()
    }
    : null

  return (
    <Group
      name="plan_element"
      key={"element_" + element.id}
      id={element.id}
      x={screenCircle.x}
      y={screenCircle.y}
      opacity={PLAN_ITEM_OPACITY}
      draggable={!!domainFunctions && !!domainFunctions.previewMove && !!domainFunctions.move}
      onDragStart={handleDragStart}
      onDragEnd={handleDragEnd}
      element={element}
      domainFunctions={domainFunctions}
      elementFunctions={{dragBoundFunc, handleDragMove}}
    >
      {renderGroupElementsFunction(screenCircle, element)}
      {
        element.x && element.y
        && <Text
          name="position"
          x={-(0.8 * screenCircle.radius) - 75}
          y={0.8 * screenCircle.radius}
          width={75}
          height={FONT_SIZE}
          fontSize={FONT_SIZE}
          align="right"
          verticalAlign="top"
          text={element.x + "/" + element.y}
          fill="black"
        />
      }
      {
        !!domainFunctions && !!domainFunctions.previewResize && !!domainFunctions.resize &&
        renderResizeHandles(
          configAreaLayout,
          screenCircle,
          handleResizing,
          handleResized,
          resizeBoundFunc
        )
      }
    </Group>
  )
}
