import {PLAN_ITEM_DRAGGING_OPACITY, PLAN_ITEM_OPACITY} from "../../../constants";

export const setOpacity = (element, action) => {
  switch (action) {
    case "resizing":
      element.find('.fill').forEach((shape) => shape.opacity(0))
      element.find('.position').forEach((shape) => shape.opacity(0))
      element.find('.material').forEach((shape) => shape.opacity(0))
      break

    case "dragging":
      element.opacity(PLAN_ITEM_DRAGGING_OPACITY)
      element.find('.fill').forEach((shape) => shape.opacity(0))
      element.find('.position').forEach((shape) => shape.opacity(0))
      element.find('.dimension').forEach((shape) => shape.opacity(0))
      element.find('.material').forEach((shape) => shape.opacity(0))
      break

    default:
      element.opacity(PLAN_ITEM_OPACITY)
      element.find('.fill').forEach((shape) => shape.opacity(1))
      element.find('.position').forEach((shape) => shape.opacity(1))
      element.find('.dimension').forEach((shape) => shape.opacity(1))
      element.find('.material').forEach((shape) => shape.opacity(1))
      break

  }
}
