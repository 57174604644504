import React, {useEffect, useState} from "react";

import {
  GoogleMap, useLoadScript, Marker
} from "@react-google-maps/api";
import {useDispatch, useSelector} from "react-redux";
import {setCoordinates} from "../../../reducers/deliverySlice";

const mapContainerStyle = {
  width: "100%", height: "600px"
}

const flums = {
  lat: 47.0943931, lng: 9.3387916
}

const center = flums

export const Maps = props => {
  const dispatch = useDispatch()
  const {address} = props
  const {isLoaded, loadError} = useLoadScript({
    googleMapsApiKey: "AIzaSyAUu7iB7-ynLYrcTScFAIEuVqL6H7Xgxo4"
  })
  const [marker, setMarker] = useState({})
  const [userSetMarker, setUserSetMarker] = useState(false)
  const [mapRefIsInitialized, setMapRefIsInitialized] = useState(false)
  const lat = useSelector(state => state.project.delivery.latitude)
  const lng = useSelector(state => state.project.delivery.longitude)

  const mapRef = React.useRef()
  const onMapLoad = React.useCallback((map) => {
    mapRef.current = map
    setMapRefIsInitialized(true)
  }, [])

  const onMapClick = React.useCallback((event) => {
    setUserSetMarker(true)
    const lat = event.latLng.lat()
    const lng = event.latLng.lng()

    setMarker({lat, lng})
    dispatch(setCoordinates({lat, lng}))
  }, [dispatch])

  const panTo = React.useCallback(({lat, lng}) => {
    mapRef.current.panTo({lat, lng});
    mapRef.current.setZoom(14);
  }, [])

  useEffect(() => {
    const geocode = (address) => {
      const geocoder = new window.google.maps.Geocoder();

      geocoder.geocode({address})
        .then(result => {
          const {results} = result;
          const place = results[0]
          const location = place.geometry.location
          const latLng = {lat: location.lat(), lng: location.lng()}

          setMarker(latLng)
          panTo(latLng)
          dispatch(setCoordinates(latLng))
        })
        .catch(error => console.log("error while fetching geocode results:", error));
    }
    if (userSetMarker) {
      return
    }

    if (isLoaded) {
      if (!!lat && !!lng) {
        if (mapRefIsInitialized) {
          const latLng = {lat, lng}

          setMarker(latLng)
          panTo(latLng)
        }

      } else if (address) {
        geocode(address)
      }
    }
  }, [address, isLoaded, lat, lng, dispatch, panTo, mapRefIsInitialized])

  if (loadError) return "Error loading maps"
  if (!isLoaded) return "Loading maps"

  return <div>
    <GoogleMap
      mapContainerStyle={mapContainerStyle}
      zoom={12}
      center={center}
      onClick={onMapClick}
      onLoad={onMapLoad}
    >
      {marker && marker.lat && marker.lng && <Marker position={{lat: marker.lat, lng: marker.lng}}></Marker>}
    </GoogleMap>
  </div>
}
