import React from "react"

import { Paper, useTheme } from "@mui/material"
import { orangeBackgroundColor } from "../../../constants"
import Grid from "@mui/material/Grid"

import { ComponentsSection } from "./ComponentsSection"
import { DimensionsSection } from "./DimensionsSection"
import { useSelector } from "react-redux"
import { LidSection } from "./LidSection"
import { FloorSection } from "./FloorSection"

export const Step1 = () => {
  const theme = useTheme()
  const vault = useSelector(state => state.plan.vault)
  const lid = useSelector(state => state.plan.lid)
  const intermediateCeiling = useSelector(
    state => state.plan.intermediateCeiling
  )
  const floor = useSelector(state => state.plan.floor)

  return (
    <Paper square={true} sx={{
      background: orangeBackgroundColor,
      padding: theme.spacing(6)
    }}>
      <Grid container spacing={6}>
        <Grid item xs={12}>
          <ComponentsSection />
        </Grid>
        {!!lid && (
          <Grid item xs={12}>
            <LidSection />
          </Grid>
        )}
        {!!floor && (
          <Grid item xs={12}>
            <FloorSection />
          </Grid>
        )}
        {(!!vault || !!lid || !!intermediateCeiling || !!floor) && (
          <Grid item xs={12}>
            <DimensionsSection />
          </Grid>
        )}
      </Grid>
    </Paper>
  )
}
