import React from 'react'
import {Route, Switch, useRouteMatch} from "react-router-dom"
import {Grid, Container, CssBaseline, Paper, GlobalStyles} from '@mui/material'
import {useTheme} from "@mui/material/styles";

import imgSrcLogo from "../../image/MM_logo.png"
import {grayBackgroundColor} from "../../constants"

import {CreatePlan} from "./CreatePlan"
import {CreateProject} from './'
import {RequestFailed} from "./RequestFailed"
const paperStyles = (theme) => ({
  width: '100%',
  marginTop: theme.spacing(6),
  padding: theme.spacing(4),
  display: 'flex',
  flexDirection: 'column',
  backgroundColor: grayBackgroundColor,
});

export const ApiRequest = () => {
  const theme = useTheme()
  const match = useRouteMatch()

  return (
    <>
      <GlobalStyles styles={{body: {marginTop: theme.spacing(8)}}}/>
      <Container
        component="main"
        maxWidth="sm"
        sx={{
          paddingBottom: theme.spacing(4),
          minWidth: '750px'
        }}
      >
        <CssBaseline/>
        <Grid container>
          <img src={imgSrcLogo} alt="Mannhart" style={{width: '15%', margin: 'auto'}}/>
          <Paper square={true} sx={paperStyles(theme)}>
            <Switch>
              <Route path={`${match.path}/createplan`} component={CreatePlan}/>
              <Route path={`${match.path}/createproject`} component={CreateProject}/>
              <Route path={`${match.path}/requestfailed`} render={(props) => <RequestFailed {...props}/>}/>
            </Switch>
          </Paper>
        </Grid>
      </Container>
    </>
  )
}

