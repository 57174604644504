import React, {forwardRef, useCallback, useEffect, useImperativeHandle} from "react"
import {useDispatch, useSelector} from "react-redux"
import {Layer, Rect, Stage, Transformer} from "react-konva"
import {createPart} from "../GroundPlan/part"
import {createHorizontalMeasurements, createVerticalMeasurements} from "../../measurement"
import {getPartialXMeasurements, getPartialYMeasurements} from "../../configurationPlanMeasurement"
import {createVerticallyCenteredGrid} from "../grid"
import {AutoResizeDiv} from "../../AutoResizeDiv"
import {renderToolbar} from "./floorPlanToolbar"
import {createCircularElementCrossHairLayer} from "../../Element/circularElementCrossHairs"
import {renderGroupElements as renderDrainageGroupElements} from "../../Element/drainageRenderFactory"
import {renderCircularPlanElement} from "../../Element/circularElementFunctions"
import {createFloorDomainFunctions} from "../../../domain/floorDomainFunctions";
import {getBevelWidth} from "../../../domain/floor";
import {createGroundPlanLayout} from "../planLayout";
import uuid from "react-uuid";
import {selectionActions} from "../../../../actions";
import {useConfigurationPlanInteractions} from "../configurationPlanInteractions";
import {useCopyPaste} from "../CopyPasteContext";

const Plan = forwardRef((props, ref) => {
  const {clientSize} = props
  const dispatch = useDispatch()
  const plan = useSelector(state => state.plan)
  const selectedIds = useSelector(state => state.selection)
  const layout = createGroundPlanLayout(clientSize, plan)
  const domainFunctions = createFloorDomainFunctions(dispatch, plan.length, plan.width)
  const {copy, paste} = useCopyPaste(domainFunctions)

  const clearSelection = useCallback(() => {
    dispatch(selectionActions.clearSelected());
  }, [dispatch]);

  useEffect(() => {
    clearSelection();
  }, [clearSelection]);

  const getHorizontalMeasurementStops = () => {
    const wallWidth = (layout.part.realWidth - layout.configArea.realWidth) / 2
    const outerStops = [wallWidth, layout.configArea.realWidth, wallWidth]
    const innerStops = getPartialXMeasurements(plan.floor.drainages, [], [], layout.configArea.realWidth)

    return innerStops.length > 1
      ? {stops: [innerStops, outerStops], offsets: [wallWidth, 0]}
      : {stops: [outerStops], offsets: [0]}
  }

  const getVerticalMeasurementStops = () => {
    const wallWidth = (layout.part.realHeight - layout.configArea.realHeight) / 2
    const outerStops = [wallWidth, layout.configArea.realHeight, wallWidth]
    const innerStops = getPartialYMeasurements(plan.floor.drainages, [], [], layout.configArea.realHeight)

    return innerStops.length > 1
      ? {stops: [outerStops, innerStops], offsets: [0, wallWidth]}
      : {stops: [outerStops], offsets: [0]}
  }

  const renderMeasurements = () => {
    const horizontalMeasurementStops = getHorizontalMeasurementStops(layout, plan.floor.drainages)
    const verticalMeasurementStops = getVerticalMeasurementStops(layout, plan.floor.drainages)

    return (
      <React.Fragment>
        {
          createHorizontalMeasurements(
            layout.horizontalMeasurements,
            horizontalMeasurementStops.stops,
            horizontalMeasurementStops.offsets
          )
        }
        {
          createVerticalMeasurements(
            layout.verticalMeasurements,
            verticalMeasurementStops.stops,
            verticalMeasurementStops.offsets
          )
        }
      </React.Fragment>
    )
  }

  const handleCopy = () => {
    const toCopy = {
      context: 'floor',
      drainages: plan.floor.drainages.filter(drainage => selectedIds.includes(drainage.id))
    }
    copy(toCopy);
  };

  const handlePaste = () => {
    const pastedObject = paste();

    if (!pastedObject || pastedObject.context !== 'floor') {
      return;
    }
    const translateY = (elements, y) =>
      Array.isArray(elements)
        ? elements.map(element => {
          return {...element, y: element.y + y}
        })
        : null
    const translateYBy = -20;
    const translated = ({
      drainages: translateY(pastedObject.drainages, translateYBy),
    })
    if (Array.isArray(pastedObject.drainages)) {
      const pastedIds = translated.drainages.map(sleeve => {
        const id = uuid();

        domainFunctions.drainageFunctions.add({...sleeve, id: id});
        return id;
      })
      dispatch(selectionActions.setSelected(pastedIds));
    }
    copy({...pastedObject, ...translated});
  };

  const handleDelete = () => {
    plan.floor.drainages.filter(el => selectedIds.includes(el.id))
      .forEach(el => domainFunctions.drainageFunctions.remove(el.id))

    dispatch(selectionActions.clearSelected)
  };

  useImperativeHandle(ref, () => ({
    delete: () => handleDelete(),
    copy: () => handleCopy(),
    paste: () => handlePaste()
  }));

  const {
    transformerRef,
    layerRef,
    selectionRectRef,
    checkDeselect,
    onMouseDown,
    onMouseMove,
    onMouseUp,
    onClickTap,
    onDblClickTap,
    onDragStart,
    handleTransformerDragStart,
    handleTransformerDragMove,
    handleTransformerDragEnd,
  } = useConfigurationPlanInteractions(ref, props, layout, handleDelete);


  return (
    <div>
      {!isNaN(layout.scale) &&
        <Stage
          name={"stage"}
          width={clientSize.width}
          height={layout.overall.screenHeight}
          onMouseDown={onMouseDown}
          onMouseUp={onMouseUp}
          onMouseMove={onMouseMove}
          onTouchStart={checkDeselect}
          onClick={onClickTap}
          onTap={onClickTap}
          onDblClick={onDblClickTap}
          onDblTap={onDblClickTap}
          onDragStart={onDragStart}
        >
          <Layer ref={layerRef}>
            {createPart(layout.part, layout.configArea)}
            {createVerticallyCenteredGrid(layout.configArea, layout.configAreaRealWorldTransform)}
            {renderMeasurements(layout, plan.floor.drainages)}
            {
              plan.floor.drainages.map(drainage => renderCircularPlanElement(
                domainFunctions.drainageFunctions,
                layout.configArea,
                drainage,
                renderDrainageGroupElements,
                getBevelWidth() * layout.scale
              ))
            }
            {renderToolbar(domainFunctions, layout.toolbar, layout.configArea)}
            <Transformer
              ref={transformerRef}
              resizeEnabled={false}
              rotateEnabled={false}
              draggable={true}
              onDragStart={handleTransformerDragStart}
              onDragMove={handleTransformerDragMove}
              onDragEnd={handleTransformerDragEnd}
            />
            <Rect fill="rgba(0,0,255,0.5)" ref={selectionRectRef}/>
          </Layer>
          {createCircularElementCrossHairLayer()}
        </Stage>
      }
    </div>
  )
});

export const FloorPlan = props => {
  const {childRef} = props

  return (
    <AutoResizeDiv>
      <Plan ref={childRef} {...props}/>
    </AutoResizeDiv>
  )
}
